import {
  EventData,
  EventRaceResults,
  HeadToHeadData,
  LeaderboardUser,
  LeaderboardVariant,
  League,
  LeagueUser,
  SplitsData,
  SplitsFiltersData,
  Team,
  UserProfile
} from './model';

const api = (accessToken?: string) => {
  const headers = () => {
    const h = { 'Content-Type': 'application/json' };
    if (accessToken) {
      h['Authorization'] = `Bearer ${accessToken}`;
    }
    return h;
  };

  const url = (path: string): string => `${window.location.origin}/api/v1/${path}`;

  const createUser = (user: any) => {
    return fetch(url('users'), {
      method: 'POST',
      body: JSON.stringify({ user }),
      headers: headers()
    });
  };

  const updateCurrentUser = (user: any) => {
    return fetch(url('users/current'), {
      method: 'PATCH',
      body: JSON.stringify({ user }),
      headers: headers()
    });
  };

  const getCurrentUser = () => {
    return fetch(url('users/current'), {
      headers: headers()
    });
  };

  const getUserDashboard = () => {
    return fetch(url('users/dashboard'), {
      headers: headers()
    });
  };

  const getAthlete = (id: number, resultsYear?: number) => {
    let path = `athletes/${id}`;
    if (resultsYear) {
      path += `?results_year=${resultsYear}`;
    }

    return fetch(url(path), {
      headers: headers()
    });
  };

  async function getAthletes(
    q: string,
    teamId?: string,
    nationality?: string,
    hasMedia?: boolean
  ): Promise<any> {
    const response = await fetch(
      url(
        `athletes?q=${q}&team_id=${teamId || ''}&nationality=${nationality || ''}&has_media=${
          hasMedia || ''
        }`
      ),
      {
        headers: headers()
      }
    );
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  const getFreeAgents = (year: number) => {
    return fetch(url(`athletes/free_agents?year=${year}`), {
      headers: headers()
    });
  };

  const getNationalities = () => {
    return fetch(url(`athletes/nationalities`), {
      headers: headers()
    });
  };

  const getEvent = (eventId: string) => {
    return fetch(url(`events/${eventId}`), {
      headers: headers()
    });
  };

  const createPicks = (eventId, picks) => {
    return fetch(url('picks'), {
      method: 'POST',
      body: JSON.stringify({ event_id: eventId, picks }),
      headers: headers()
    });
  };

  const getUserPicks = (eventId: string) => {
    return fetch(url(`events/${eventId}/user_picks`), {
      headers: headers()
    });
  };

  const getCommunityPicks = (eventId: string) => {
    return fetch(url(`events/${eventId}/community_picks`), {
      headers: headers()
    });
  };

  const downloadCommunityPicks = (eventId: string) => {
    return fetch(url(`events/${eventId}/community_picks_csv`), {
      headers: headers()
    });
  };

  const getEventLeaderboard = (eventId: string, limit: number | null = null) => {
    return fetch(url(`events/${eventId}/leaderboard${limit ? '?limit=' + limit : ''}`), {
      headers: headers()
    });
  };

  async function getEventRaceResults(eventId: string): Promise<EventRaceResults> {
    const response = await fetch(url(`events/${eventId}/race_results`), {
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  const getSeries = (seriesId: string) => {
    return fetch(url(`event_series/${seriesId}`), {
      headers: headers()
    });
  };

  const getSeriesLeaderboard = (seriesId: string, limit: number | null = null) => {
    return fetch(url(`event_series/${seriesId}/leaderboard${limit ? '?limit=' + limit : ''}`), {
      headers: headers()
    });
  };

  async function getSplits(raceId: string): Promise<SplitsData> {
    const response = await fetch(url(`splits?race_id=${raceId}`), {
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  async function getTeams(): Promise<Team[]> {
    const response = await fetch(url(`teams`), {
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.data.teams;
    } else {
      return Promise.reject(response);
    }
  }

  async function getTeam(slug: string): Promise<Team> {
    const response = await fetch(url(`teams/${slug}`), {
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.data.team;
    } else {
      return Promise.reject(response);
    }
  }

  const createLeague = (league: any) => {
    return fetch(url('leagues'), {
      method: 'POST',
      body: JSON.stringify({ league }),
      headers: headers()
    });
  };

  async function getLeague(leagueId: string): Promise<League> {
    const response = await fetch(url(`leagues/${leagueId}`), {
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.league;
    } else {
      return Promise.reject(response);
    }
  }

  async function joinLeague(leagueId: string): Promise<LeagueUser> {
    const response = await fetch(url(`leagues/${leagueId}/join`), {
      method: 'POST',
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.league_user;
    } else {
      return Promise.reject(response);
    }
  }

  async function leaveLeague(leagueId: string): Promise<any> {
    const response = await fetch(url(`leagues/${leagueId}/leave`), {
      method: 'POST',
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  async function updateLeague(
    leagueId: string,
    league: { name: string; avatar_url: string }
  ): Promise<any> {
    const response = await fetch(url(`leagues/${leagueId}`), {
      body: JSON.stringify({ league }),
      method: 'PATCH',
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body;
    } else {
      return Promise.reject(response);
    }
  }

  async function selectLeagueEvents(
    leagueId: string,
    seasonId: number,
    leagueEvents: { event_id: number }[]
  ): Promise<any> {
    const response = await fetch(url(`leagues/${leagueId}/select_events`), {
      body: JSON.stringify({
        league_season_id: seasonId,
        league_events: leagueEvents
      }),
      method: 'POST',
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body;
    } else {
      return Promise.reject(response);
    }
  }

  async function getEvents(year?: number): Promise<EventData[]> {
    const response = await fetch(url('events' + (year ? `?year=${year}` : '')), {
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.data.events;
    } else {
      return Promise.reject(response);
    }
  }

  async function getYearLeaderboard(
    year: string | number,
    leagueId?: string,
    eventId?: string,
    variant?: LeaderboardVariant
  ): Promise<LeaderboardUser[]> {
    const params = new URLSearchParams();
    if (leagueId) {
      params.append('league_id', leagueId);
    }
    if (eventId) {
      params.append('event_id', eventId);
    }
    if (variant) {
      params.append('variant', variant);
    }

    const response = await fetch(url(`leaderboards/${year}?${params}`), {
      headers: headers()
    });

    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  async function getAthletesHeadToHead(athleteIds: [string, string]): Promise<HeadToHeadData[]> {
    const response = await fetch(url(`athletes/head_to_head?athlete_ids=${athleteIds.join(',')}`), {
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  async function authorizeStrava(): Promise<{ redirect_url: string }> {
    const response = await fetch(url(`users/authorize_strava`), {
      method: 'POST',
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  async function deauthorizeStrava(): Promise<{ success: boolean }> {
    const response = await fetch(url(`users/deauthorize_strava`), {
      method: 'POST',
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  async function createStravaAccount(code: string, scope: string): Promise<{ success: boolean }> {
    const response = await fetch(url(`users/strava_account`), {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify({
        strava_account: {
          code,
          scope
        }
      })
    });
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  async function getCurrentUserProfile(): Promise<UserProfile> {
    const response = await fetch(url('users/current_profile'), {
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  async function claimAthlete(athleteId: string): Promise<any> {
    const response = await fetch(url(`athletes/${athleteId}/claim`), {
      method: 'POST',
      headers: headers()
    });
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  async function updateAthlete(athleteId: string, athlete: any): Promise<any> {
    const response = await fetch(url(`athletes/${athleteId}`), {
      method: 'PATCH',
      headers: headers(),
      body: JSON.stringify({ athlete })
    });
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  async function getSplitFilters(raceId: string, query: string): Promise<SplitsFiltersData> {
    const response = await fetch(url(`splits/filters`), {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify({
        race_id: raceId,
        query
      })
    });
    if (response.ok) {
      const body = await response.json();
      return body.data;
    } else {
      return Promise.reject(response);
    }
  }

  return {
    authorizeStrava,
    deauthorizeStrava,
    claimAthlete,
    createStravaAccount,
    createLeague,
    createPicks,
    createUser,
    downloadCommunityPicks,
    getAthlete,
    getAthletes,
    getAthletesHeadToHead,
    getNationalities,
    getCommunityPicks,
    getCurrentUser,
    getCurrentUserProfile,
    getEvent,
    getEventLeaderboard,
    getEventRaceResults,
    getEvents,
    getFreeAgents,
    getLeague,
    getSeries,
    getSeriesLeaderboard,
    getSplits,
    getSplitFilters,
    getTeams,
    getTeam,
    getUserDashboard,
    getUserPicks,
    getYearLeaderboard,
    joinLeague,
    leaveLeague,
    selectLeagueEvents,
    updateAthlete,
    updateCurrentUser,
    updateLeague
  };
};

export function pickAttributes(o: any, attrs: string[]): any {
  return attrs.reduce((acc, attr) => {
    acc[attr] = o[attr];
    return acc;
  }, {});
}

export default api;
