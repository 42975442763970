import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import api from '../lib/api';
import PageHeader from '../components/page-header';
import UserContext from '../context/user-context';
import Footer from '../components/footer';
import { EventData } from '../lib/model';
import MainContent, { PaddedContent } from '../components/main-content';
import EventsList from '../components/events-list';
import FlashMessage from '../components/flash-message';
import { Link } from 'react-router-dom';

const Events = () => {
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState<EventData[]>([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsData = await api(user.accessToken).getEvents();
      setEvents(eventsData);
      setIsLoading(false);
    };

    fetchEvents();
  }, [user.isAuthenticated]);

  return (
    <>
      <PageHeader title="Events" subTitle="Browse recent and upcoming events" />
      <MainContent>
        <PaddedContent>
          <FlashMessage type="announcement">
            <p>
              <span className="badge badge-primary mr-1 font-medium">NEW</span> Score bonuses! See
              the{' '}
              <Link to="/faqs" className="underline">
                FAQs page
              </Link>{' '}
              for info.
            </p>
          </FlashMessage>
        </PaddedContent>
        <EventsList events={events} isLoading={isLoading} containerClassName="bg-base-300 mt-4" />
      </MainContent>
      <Footer />
    </>
  );
};

export default Events;
