import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { EventData } from '../lib/model';
import StatusBadge from './status-badge';
import SeriesBadges from './series-badges';
import Loading from './loading';
import NationalityFlag from './nationality-flag';

interface EventsListProps {
  events: EventData[];
  isLoading: boolean;
  containerClassName?: string;
  includeSeries?: boolean;
}

const EventsList: React.FC<EventsListProps> = ({
  events,
  isLoading,
  containerClassName = '',
  includeSeries = true
}) => {
  const navigate = useNavigate();

  return (
    <div className={`overflow-x-scroll sm:overflow-hidden ${containerClassName}`}>
      <div className="overflow-x-auto">
        <table className="table-md table">
          <thead className="dark:bg-base-100 dark:text-base-content bg-zinc-900 py-2 text-white">
            <tr className="text-sm">
              <th className="py-4 font-medium">Race</th>
              <th className="font-medium">Status</th>
              <th className="font-medium">Date</th>
              <th className="font-medium">Location</th>
              {includeSeries && <th className="font-medium">Series</th>}
            </tr>
          </thead>
          <tbody className="bg-base-100 dark:bg-transparent">
            {isLoading && (
              <tr>
                <td colSpan={6}>
                  <Loading />
                </td>
              </tr>
            )}
            {!isLoading &&
              events.map((event) => (
                <tr
                  key={event.uuid}
                  onClick={() => navigate(`/events/${event.uuid}`)}
                  className="dark:hover:bg-secondary hover:bg-base-100 dark:border-base-100/50 border-base-200 cursor-pointer border-b bg-white/50 dark:bg-transparent"
                >
                  <td className="min-w-[200px]">
                    <div className="flex items-center gap-3">
                      <div className="avatar">
                        <div className="w-10 rounded-full">
                          <Link to={`/events/${event.uuid}`}>
                            <img src={event.image_url} alt={event.name} />
                          </Link>
                        </div>
                      </div>
                      <div>
                        <div className="font-normal">
                          <Link to={`/events/${event.uuid}`} className="">
                            {event.name}
                          </Link>
                        </div>
                        <div className="text-base-content/50 text-sm">{event.format}</div>
                      </div>
                    </div>
                  </td>
                  <td className="min-w-[200px]">
                    <StatusBadge status={event.status} />
                  </td>
                  <td className="min-w-[100px]">
                    {event.start_date
                      ? format(parseISO(event.start_date), 'LLL d, yyyy')
                      : event.dates}
                  </td>
                  <td>
                    <div className="capitalize">
                      <span className="mr-2">
                        <NationalityFlag country={event.country} />
                      </span>
                      {event.location}
                    </div>
                  </td>
                  {includeSeries && (
                    <td>
                      <SeriesBadges event={event} />
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventsList;
