import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import api from '../lib/api';
import PageHeader from '../components/page-header';
import { CalendarIcon, LocationMarkerIcon, UserIcon, HashtagIcon } from '@heroicons/react/solid';
import UserContext from '../context/user-context';
import { useAuth0 } from '@auth0/auth0-react';
import StatusBadge from '../components/status-badge';
import {
  ContestType,
  Entrant,
  EntrantType,
  EventPlatform,
  EventSponsor,
  EventStatus,
  FIELD_ALL,
  FIELD_FEMALE,
  FIELD_MALE
} from '../lib/model';
import FlashMessage from '../components/flash-message';
import SectionDescription from '../components/section-description';
import Footer from '../components/footer';
import { loginAndRedirect } from '../lib/auth';
import SeriesBadges from '../components/series-badges';
import Leaderboard from '../components/leaderboard';
import AthleteProfileModal from '../components/athlete-profile-modal';
import { parseISO } from 'date-fns';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import Tooltip from '../components/tooltip';
import { normalize } from '../lib/strings';
import MainContent, { PaddedContent } from '../components/main-content';
import PicksTable from '../components/picks-table';
import ScorecardModal from '../components/scorecard-modal';
import PostSubmissionModal from '../components/post-submission-modal';
import EventCountdown from '../components/event-countdown';
import EntrantsList from '../components/entrants-list';
import CopyPicksButton from '../components/copy-picks-button';
import { LoadingPage } from '../components/loading';

function isNicknameMatch(query: string, entrantName: string): boolean {
  return [
    ['speedgoat', 'Karl Meltzer'],
    ['dbo', 'Dylan Bowman'],
    ['freetrail', 'Dylan Bowman'],
    ['mvd', 'Matt Van Dalsem'],
    ['petergod', 'Adam Peterman']
  ].some(([nickname, fullName]) => {
    return query === nickname && entrantName === fullName;
  });
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Event = () => {
  const user = useContext(UserContext);
  const query = useQuery();
  const { loginWithRedirect } = useAuth0();
  const [event, setEvent] = useState({});
  const [leaderboard, setLeaderboard] = useState([]);
  const [entrants, setEntrants] = useState([]);
  const [femalePicks, _setFemalePicks] = useState([]);
  const [malePicks, _setMalePicks] = useState([]);
  const [femaleCommunityPicks, setFemaleCommunityPicks] = useState([]);
  const [maleCommunityPicks, setMaleCommunityPicks] = useState([]);
  const [isLoadingUserPicks, setIsLoadingUserPicks] = useState(true);
  const [isLoadingCommunityPicks, setIsLoadingCommunityPicks] = useState(true);
  const [queryFilter, setQueryFilter] = useState(query.get('q') || '');
  const [fieldFilter, setFieldFilter] = useState(FIELD_ALL);
  const [submitPicksModalOpen, setSubmitPicksModalOpen] = useState(false);
  const [athleteInfoModalOpen, setAthleteInfoModalOpen] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState<Entrant | undefined>(undefined);
  const [savingPicks, setSavingPicks] = useState(false);
  const [picksSaved, setPicksSaved] = useState(true);
  const [communitySize, setCommunitySize] = useState(0);
  const [loadFullLeaderboard, setLoadFullLeaderboard] = useState(false);
  const [eventUser, setEventUser] = useState({});
  const [scorecardModalOpen, setScorecardModalOpen] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (user.isLoading) return;

    api(user.accessToken)
      .getEvent(params.eventId)
      .then((response) => {
        response.json().then((body) => {
          setEvent(body.data);

          if (body.data.status === EventStatus.ResultsPosted) {
            api(user.accessToken)
              .getEventLeaderboard(params.eventId)
              .then((response) => {
                response.json().then((body) => {
                  setLeaderboard(body.data.users);
                });
              });
          }

          // Retrieve community picks if event is not open
          if ([EventStatus.Closed, EventStatus.ResultsPosted].includes(body.data.status)) {
            api(user.accessToken)
              .getCommunityPicks(params.eventId)
              .then((response) => {
                response.json().then((body) => {
                  const fPicks = [];
                  const mPicks = [];

                  body.data.picks.forEach((pick) => {
                    switch (pick.entrant.field) {
                      case FIELD_FEMALE:
                        fPicks.push(pick.entrant);
                        break;
                      case FIELD_MALE:
                        mPicks.push(pick.entrant);
                        break;
                    }
                  });

                  setFemaleCommunityPicks(fPicks);
                  setMaleCommunityPicks(mPicks);
                  setCommunitySize(body.data.community_size);
                  setIsLoadingCommunityPicks(false);
                });
              });
          } else {
            setIsLoadingCommunityPicks(false);
          }

          // Retrieve existing event picks for authenticated user
          if (user.isAuthenticated) {
            setIsLoadingUserPicks(true);

            api(user.accessToken)
              .getUserPicks(params.eventId)
              .then((response) => {
                response.json().then((body) => {
                  const fPicks = [];
                  const mPicks = [];

                  body.data.picks.forEach((pick) => {
                    switch (pick.entrant.field) {
                      case FIELD_FEMALE:
                        fPicks.push(pick.entrant);
                        break;
                      case FIELD_MALE:
                        mPicks.push(pick.entrant);
                        break;
                    }
                  });

                  setEventUser(body.data);
                  _setFemalePicks(fPicks);
                  _setMalePicks(mPicks);
                  setIsLoadingUserPicks(false);
                });
              });
          } else {
            setIsLoadingUserPicks(false);
          }
        });
      });
  }, [user.isAuthenticated, user.isLoading]);

  useEffect(() => {
    if (event?.entrants?.length) {
      filterEntrants(queryFilter, FIELD_ALL);
    }
  }, [event.entrants]);

  useEffect(() => {
    if (loadFullLeaderboard) {
      api(user.accessToken)
        .getEventLeaderboard(params.eventId, 2000)
        .then((response) => {
          response.json().then((body) => {
            setLeaderboard(body.data.users);
          });
        });
    }
  }, [user.isAuthenticated, loadFullLeaderboard]);

  const actionsDisabled = event.status !== EventStatus.Open || !user.isAuthenticated;

  const savePicks = (fPicks, mPicks) => {
    if (!user.isAuthenticated) {
      return;
    }

    const picks = [fPicks, mPicks]
      .map((_picks) => {
        return _picks.map((entrant, i) => {
          return {
            entrant_id: entrant.id,
            rank: i + 1
          };
        });
      })
      .flat();

    return api(user.accessToken).createPicks(event.id, picks);
  };

  const setFemalePicks = (picks) => {
    _setFemalePicks(picks);
  };

  const setMalePicks = (picks) => {
    _setMalePicks(picks);
  };

  const doQueryFilter = (query: string) => {
    setQueryFilter(query);
    filterEntrants(query, fieldFilter);
  };

  const doFieldFilter = (field: string) => {
    setFieldFilter(field);
    filterEntrants(queryFilter, field);
  };

  const filterEntrants = (qf: string, ff: string) => {
    const query = qf.toLowerCase();
    if (query === '' && ff === FIELD_ALL) {
      return setEntrants(event.entrants);
    }

    const matches = (value: string) => {
      return normalize((value || '').toLowerCase()).includes(normalize(query));
    };

    setEntrants(
      event.entrants.filter((entrant) => {
        const matchesQuery =
          query === '' ||
          matches(entrant.first_name) ||
          matches(entrant.last_name) ||
          matches(`${entrant.first_name} ${entrant.last_name}`) ||
          matches(entrant.city) ||
          matches(entrant.state_or_country) ||
          isNicknameMatch(query, `${entrant.first_name} ${entrant.last_name}`);

        const matchesField = ff === FIELD_ALL || entrant.field === ff;

        return matchesField && matchesQuery;
      })
    );
  };

  const addPick = (entrant) => {
    switch (entrant.field) {
      case FIELD_FEMALE:
        setFemalePicks([...femalePicks, entrant]);
        break;
      case FIELD_MALE:
        setMalePicks([...malePicks, entrant]);
        break;
    }
    setPicksSaved(false);
  };

  const removePick = (entrant) => {
    switch (entrant.field) {
      case FIELD_FEMALE:
        setFemalePicks(femalePicks.filter((e) => e.id !== entrant.id));
        break;
      case FIELD_MALE:
        setMalePicks(malePicks.filter((e) => e.id !== entrant.id));
        break;
    }
    setPicksSaved(false);
  };

  const movePick = (entrant, oldPosition, newPosition) => {
    let newPicks = [];

    switch (entrant.field) {
      case FIELD_FEMALE:
        newPicks = [...femalePicks];
        newPicks[oldPosition] = femalePicks[newPosition];
        newPicks[newPosition] = femalePicks[oldPosition];
        setFemalePicks(newPicks);
        break;
      case FIELD_MALE:
        newPicks = [...malePicks];
        newPicks[oldPosition] = malePicks[newPosition];
        newPicks[newPosition] = malePicks[oldPosition];
        setMalePicks(newPicks);
        break;
    }

    setPicksSaved(false);
  };

  const submitPicks = () => {
    setSavingPicks(true);
    savePicks(femalePicks, malePicks).then((response) => {
      if (response.ok) {
        setSubmitPicksModalOpen(true);
        setPicksSaved(true);
      } else if (response.status === 422) {
        window.location.reload();
      }

      setSavingPicks(false);
    });
  };

  const showAthleteInfoModal = (entrant) => {
    setSelectedAthlete(entrant);
    setAthleteInfoModalOpen(true);
  };

  const savingPicksDisabled =
    savingPicks ||
    picksSaved ||
    !user.isAuthenticated ||
    actionsDisabled ||
    (malePicks.length === 0 && femalePicks.length === 0);

  const maxPicksPerField = [ContestType.PickFive, ContestType.VoteFive].includes(event.contest_type)
    ? 5
    : 10;
  const isVoteContest = [ContestType.VoteFive, ContestType.VoteTen].includes(event.contest_type);
  const hasPerformanceEntrants = event.entrant_type === EntrantType.Performance;
  const femalePicksFull = femalePicks.length >= maxPicksPerField;
  const malePicksFull = malePicks.length >= maxPicksPerField;
  const onUltraSignup = event.platform === EventPlatform.UltraSignup;
  const userName = user.isAuthenticated ? user.displayName : '';
  const allPicks = [...femalePicks, ...malePicks];
  const eventYear = parseISO(event.closes_at).getFullYear();

  let sponsor: EventSponsor | undefined = undefined;

  if (!event.id) {
    return <LoadingPage />;
  }

  return (
    <>
      <PageHeader
        title={
          <>
            <div className="hidden sm:block">
              <img className="mr-3 inline h-10 w-10 rounded-full" src={event.image_url} />
              <span className="text-xl sm:text-2xl">{event.name_and_format}</span>
            </div>
            <div className="sm:hidden">
              <img className="mx-auto mb-4 h-20 w-20 rounded-full" src={event.image_url} />
              <p className="text-center text-xl">{event.name_and_format}</p>
            </div>
          </>
        }
        subTitle={
          <>
            <div className="flex hidden flex-col space-y-4 sm:block">
              <div className="mt-1 flex flex-row sm:flex-wrap sm:space-x-6">
                <div className="flex items-center capitalize">
                  <LocationMarkerIcon className="mr-1 h-4 w-4 flex-shrink-0" aria-hidden="true" />
                  {event.location}
                </div>
                <div className="flex items-center">
                  <CalendarIcon className="mr-1 h-4 w-4 flex-shrink-0" aria-hidden="true" />
                  {event.dates}
                </div>
                {event.hashtag && (
                  <div className="flex items-center">
                    <HashtagIcon className="h-4 w-4 flex-shrink-0" aria-hidden="true" />
                    <a
                      href={`https://twitter.com/search?q=%23${event.hashtag}&f=live`}
                      target="_blank"
                    >
                      {event.hashtag}
                    </a>
                  </div>
                )}
                <div className="flex items-center">
                  <StatusBadge status={event.status} />
                </div>
              </div>
              {Boolean(event.event_series.length) && (
                <div>
                  <SeriesBadges event={event} />
                </div>
              )}
              {Boolean(event.platform_url) && (
                <div>
                  <a
                    className="text-purple-haze-500 hover:text-purple-haze-900 text-sm font-normal underline"
                    target="_blank"
                    href={event.platform_url}
                  >
                    {onUltraSignup
                      ? 'View on UltraSignup'
                      : isVoteContest
                        ? 'View Voting Information'
                        : 'View Race Information'}
                  </a>
                </div>
              )}
            </div>
            <div className="sm:hidden">
              <div className="mt-3 grid grid-cols-1 gap-2 text-center text-sm">
                <div className="flex items-center justify-center capitalize">
                  <LocationMarkerIcon className="mr-1 h-3 w-3 flex-shrink-0" aria-hidden="true" />
                  {event.location}
                </div>
                <div className="flex items-center justify-center">
                  <CalendarIcon className="mr-1 h-3 w-3 flex-shrink-0" aria-hidden="true" />
                  {event.dates}
                </div>
              </div>
              <div className="my-4 text-center">
                <StatusBadge status={event.status} />
              </div>
              {Boolean(event.platform_url) && (
                <div className="my-2 text-center">
                  <a
                    className="hyperlink text-sm font-normal underline"
                    target="_blank"
                    href={event.platform_url}
                  >
                    {onUltraSignup
                      ? 'View on UltraSignup'
                      : isVoteContest
                        ? 'View Voting Information'
                        : 'View Race Information'}
                  </a>
                </div>
              )}
            </div>
          </>
        }
        sponsor={sponsor}
      />
      <MainContent>
        <PaddedContent>
          {Boolean(event.announcement) && (
            <FlashMessage type="announcement">
              <ReactMarkdown className="announcement">{event.announcement}</ReactMarkdown>
            </FlashMessage>
          )}
          {event.status === EventStatus.ResultsPosted && leaderboard.length > 0 && (
            <>
              <h2 className="mb-3 font-semibold">
                Fantasy Leaderboard
                <Tooltip>Check out our FAQs page to learn how scores are calculated.</Tooltip>
              </h2>
              <SectionDescription className="mb-4">
                The results are in! Check out the leaderboard to see whose picks rose above the
                rest!
              </SectionDescription>
              {user.isAuthenticated &&
                (eventUser.picks || []).some((p) => p.score !== null && p.score !== undefined) && (
                  <button
                    className="hyperlink mb-4 block text-sm underline"
                    onClick={() => setScorecardModalOpen(true)}
                  >
                    View Your Scorecard
                  </button>
                )}
              {communitySize > leaderboard.length && !loadFullLeaderboard && (
                <p className="mb-3 text-xs">
                  <button onClick={() => setLoadFullLeaderboard(true)} className="underline">
                    Show all scores
                  </button>
                </p>
              )}
              <div className="flex-row sm:flex">
                <Leaderboard users={leaderboard} communitySize={communitySize} />
              </div>
              {scorecardModalOpen && (
                <ScorecardModal
                  event={event}
                  onClose={() => setScorecardModalOpen(false)}
                  eventUser={eventUser}
                  communitySize={communitySize}
                />
              )}
            </>
          )}
          {event.status === EventStatus.Closed && (
            <FlashMessage type="notice">
              {isVoteContest ? (
                <span>
                  Voting has concluded. Results will be announced in early {eventYear + 1}!
                </span>
              ) : (
                <span>
                  Picks are locked in for this event. Come back after the race results have been
                  posted to see who was closest to the mark!
                </span>
              )}
            </FlashMessage>
          )}
          {event.status === EventStatus.Selected &&
            (isVoteContest ? (
              <FlashMessage type="notice">
                Voting is not yet open. You can browse the candidates below.
              </FlashMessage>
            ) : (
              <FlashMessage type="notice">
                This event isn’t yet open for making picks
                {event.entrants_count ? ', but you can browse the entrants list below' : ''}.
              </FlashMessage>
            ))}
          {event.status != EventStatus.Selected &&
            (user.isAuthenticated || event.status === EventStatus.Open) && (
              <>
                {!user.isAuthenticated && <h2 className="mb-3 font-semibold">Your Picks</h2>}
                {user.isAuthenticated && (
                  <>
                    <h2 className="relative mb-3 font-semibold">
                      My Picks
                      <Tooltip>Your picks are private unless you choose to share them.</Tooltip>
                    </h2>
                  </>
                )}
                <SectionDescription>
                  Use this section to manage your picks.
                  {event.status === EventStatus.Open && (
                    <>
                      &nbsp;Once you’re happy with your selections, use the "Submit" button to save
                      them! You can update your picks as many times as you'd like before the race
                      starts. Make <span className="font-medium">{maxPicksPerField} picks</span> per
                      field!
                    </>
                  )}
                </SectionDescription>
                {!user.isAuthenticated && (
                  <FlashMessage type="info">
                    <span>
                      <a
                        href="#"
                        className="underline"
                        onClick={() => loginAndRedirect(loginWithRedirect)}
                      >
                        Register or sign in
                      </a>{' '}
                      to make your picks!
                    </span>
                  </FlashMessage>
                )}
                <div className="mb-4">
                  {event.status === EventStatus.Open && (
                    <>
                      <button
                        className="btn btn-primary mr-2"
                        disabled={savingPicksDisabled}
                        onClick={() => submitPicks()}
                      >
                        {savingPicks ? 'Submitting...' : <>Submit</>}
                      </button>
                    </>
                  )}
                  <CopyPicksButton
                    malePicks={malePicks}
                    femalePicks={femalePicks}
                    event={event}
                    isCommunity={false}
                    emphasize={event.status !== EventStatus.Open}
                  />
                  {event.status === EventStatus.Open && (
                    <div className="ml-2 inline-block">
                      <EventCountdown closesAt={event.closes_at} />
                    </div>
                  )}
                  {submitPicksModalOpen && (
                    <PostSubmissionModal
                      isVoteContest={isVoteContest}
                      onClose={() => setSubmitPicksModalOpen(false)}
                      maxPicksPerField={maxPicksPerField}
                      picksCount={allPicks.length}
                      eventClosesAt={event.closes_at}
                    />
                  )}
                </div>
                {user.isAuthenticated && !picksSaved && (
                  <div>
                    <FlashMessage type="notice" small>
                      You’ve updated your picks! Remember to save them before you hit the trail!
                    </FlashMessage>
                  </div>
                )}
                <div className="flex-row lg:flex lg:space-x-8">
                  <PicksTable
                    actionsDisabled={actionsDisabled}
                    event={event}
                    picks={femalePicks}
                    heading="Women’s Picks"
                    movePick={movePick}
                    picksLoading={isLoadingUserPicks}
                    removePick={removePick}
                    maxPicksPerField={maxPicksPerField}
                    showAthleteInfoModal={showAthleteInfoModal}
                  />
                  <PicksTable
                    actionsDisabled={actionsDisabled}
                    event={event}
                    picks={malePicks}
                    heading="Men’s Picks"
                    movePick={movePick}
                    picksLoading={isLoadingUserPicks}
                    removePick={removePick}
                    maxPicksPerField={maxPicksPerField}
                    showAthleteInfoModal={showAthleteInfoModal}
                  />
                </div>
                {false && (
                  <div className="mb-8">
                    <h2 className="mb-3 font-semibold">Predict Winning Times</h2>
                    <SectionDescription>
                      Predict the winning time for each field! This is optional and won’t impact
                      your score, but it will be used for breaking ties! We'll also display a second
                      leaderboard after the race ranking your guesses. Like your picks, you can
                      update your guesses as many times as you like until the event is locked.
                    </SectionDescription>
                    <div>
                      <h3 className="mb-3 text-xs font-medium uppercase">Women's Race</h3>
                      <div className="my-4 flex">
                        <div className="mr-4 flex h-[45px]">
                          <input
                            type="number"
                            min={0}
                            max={200}
                            className="inline-block h-full w-[65px] text-sm"
                          />
                          <label className="bg-zinc-900 inline-block flex h-full place-items-center px-4 text-xs uppercase text-white">
                            Hours
                          </label>
                        </div>
                        <div className="mr-4 flex h-[45px]">
                          <input
                            type="number"
                            min={0}
                            max={59}
                            className="inline-block h-full w-[65px] text-sm"
                          />
                          <label className="bg-zinc-900 inline-block flex h-full place-items-center px-4 text-xs uppercase text-white">
                            Minutes
                          </label>
                        </div>
                        <div className="flex h-[45px]">
                          <input
                            type="number"
                            min={0}
                            max={59}
                            className="inline-block h-full w-[65px] text-sm"
                          />
                          <label className="bg-zinc-900 inline-block flex h-full place-items-center px-4 text-xs uppercase text-white">
                            Seconds
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!isVoteContest && (
                  <>
                    <h2 className="mb-3 font-semibold">
                      Community Picks
                      <Tooltip>
                        We use a rudimentary Borda count to calculate these rankings.
                      </Tooltip>
                    </h2>
                    {event.status === EventStatus.Open && (
                      <div className="mb-8">
                        <FlashMessage type="info">
                          Community picks will be posted once all picks are locked in. Check back
                          after the race starts to see the consensus predictions for each field!
                        </FlashMessage>
                      </div>
                    )}
                    {event.status !== EventStatus.Open &&
                      ([...femaleCommunityPicks, ...maleCommunityPicks].length ? (
                        <>
                          <SectionDescription>
                            Here are your top contenders as voted by the community.
                          </SectionDescription>
                          <button
                            className="hyperlink mb-6 block text-sm underline"
                            onClick={() =>
                              api(user.accessToken)
                                .downloadCommunityPicks(event.uuid)
                                .then((response) => {
                                  response.blob().then((blob) => {
                                    const url = window.URL.createObjectURL(blob);
                                    const link = document.createElement('a');
                                    link.style.display = 'none';
                                    link.href = url;
                                    link.setAttribute(
                                      'download',
                                      `${event.name} - ${event.dates} - Freetrail Fantasy Community Picks.csv`
                                    );
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                    window.URL.revokeObjectURL(url);
                                  });
                                })
                            }
                          >
                            Download Aggregate Data
                          </button>
                          {communitySize > 1 && (
                            <p className="mb-4 text-xs">
                              Rankings based on picks submitted by {communitySize} community
                              members.
                            </p>
                          )}
                          <div className="flex-row lg:flex lg:space-x-8">
                            <PicksTable
                              actionsDisabled={actionsDisabled}
                              event={event}
                              picks={femaleCommunityPicks}
                              heading="Women’s Picks"
                              picksLoading={isLoadingCommunityPicks}
                              communityPicks
                              comparisonPicks={femalePicks}
                              maxPicksPerField={maxPicksPerField}
                              showAthleteInfoModal={showAthleteInfoModal}
                            />
                            <PicksTable
                              actionsDisabled={actionsDisabled}
                              event={event}
                              picks={maleCommunityPicks}
                              heading="Men’s Picks"
                              picksLoading={isLoadingCommunityPicks}
                              communityPicks
                              comparisonPicks={malePicks}
                              maxPicksPerField={maxPicksPerField}
                              showAthleteInfoModal={showAthleteInfoModal}
                            />
                          </div>
                        </>
                      ) : (
                        !isLoadingCommunityPicks && (
                          <FlashMessage type="notice">
                            We need more data before we can show community picks for this event.
                          </FlashMessage>
                        )
                      ))}
                  </>
                )}
              </>
            )}
          <h2 className="relative mb-3 font-semibold" id="entrants">
            {hasPerformanceEntrants ? 'Performances' : 'Athletes'}
            {!isVoteContest && <Tooltip>Entrant data may lag behind official start list.</Tooltip>}
          </h2>
          {isVoteContest ? (
            hasPerformanceEntrants ? (
              <SectionDescription>
                Filter the performance list by athlete name and field. Use the "Pick" button to add
                a performance to your picks. Click on an athlete’s name to learn more about their
                performance.
              </SectionDescription>
            ) : (
              <SectionDescription>
                Filter the athletes list by name, location, and field. Use the "Pick" button to add
                a runner to your picks. Click on an athlete’s name to view a summary of their{' '}
                {eventYear} results.
              </SectionDescription>
            )
          ) : (
            <SectionDescription>
              Filter the entrants list by name, location, and field. Use the "Pick" button to add a
              runner to your picks. Click on an athlete’s name to learn more about them.
            </SectionDescription>
          )}
          {athleteInfoModalOpen && selectedAthlete !== undefined && (
            <AthleteProfileModal
              athleteId={selectedAthlete.athlete_id}
              firstName={selectedAthlete.first_name}
              lastName={selectedAthlete.last_name}
              location={[selectedAthlete.city, selectedAthlete.state_or_country]
                .filter((v) => v)
                .join(', ')}
              notes={selectedAthlete.notes}
              onClose={() => setAthleteInfoModalOpen(false)}
              resultsYear={isVoteContest ? eventYear : undefined}
              resultId={isVoteContest ? selectedAthlete.result_id : undefined}
              itraId={selectedAthlete.itra_id}
              utmbId={selectedAthlete.utmb_id}
            />
          )}
          {entrants.length > 0 && (
            <EntrantsList
              entrants={entrants}
              event={event}
              actionsDisabled={actionsDisabled}
              isVoteContest={isVoteContest}
              hasPerformanceEntrants={hasPerformanceEntrants}
              onUltraSignup={onUltraSignup}
              allPicks={allPicks}
              femalePicksFull={femalePicksFull}
              malePicksFull={malePicksFull}
              onAddPick={addPick}
              onRemovePick={removePick}
              onShowAthleteInfo={showAthleteInfoModal}
            />
          )}
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default Event;
