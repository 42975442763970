import React, { useContext, useState, useCallback } from 'react';
import UserContext from '../context/user-context';
import api from '../lib/api';
import LeagueSettingsForm from './league-settings-form';

const CreateLeagueModal = ({ onClose }) => {
  const user = useContext(UserContext);
  const [isSaving, setIsSaving] = useState(false);
  const [league, setLeague] = useState({
    name: '',
    avatar_url: ''
  });

  const handleSubmit = useCallback(
    ({ name, avatarUrl }: { name: string; avatarUrl: string }, after: () => void) => {
      setIsSaving(true);
      api(user.accessToken)
        .createLeague({ name, avatar_url: avatarUrl })
        .then((response) => {
          response.json().then((body) => {
            window.location.href = `/leagues/${body.league.uuid}?settings=true`;
          });
        });
    },
    [league, user]
  );

  return (
    <div className="modal modal-open">
      <div className="modal-box bg-base-200 relative max-w-lg">
        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>
          ✕
        </button>
        <h3 className="mb-4 text-lg font-semibold">Create League</h3>
        <p className="mb-6 text-xs leading-relaxed">
          Leagues allow you to compete against others on a private leaderboard for events of your
          choosing. Once you create a league, you can invite your friends to join it via a unique
          invite link.
        </p>

        <LeagueSettingsForm isSaving={isSaving} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default CreateLeagueModal;
