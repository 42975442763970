import React from 'react';

interface MainContentProps {
  hasMinHeight?: boolean;
}

const MainContent: React.FC<React.PropsWithChildren<MainContentProps>> = ({
  children,
  hasMinHeight = true
}) => (
  <main className="mx-auto bg-[#bbb] py-6 pb-12 shadow-md dark:bg-transparent sm:px-6 lg:px-8">
    <div
      className={`main-content bg-base-200 mx-auto max-w-screen-2xl rounded-none py-8 sm:rounded-lg ${
        hasMinHeight ? 'min-h-[650px]' : ''
      }`}
    >
      <div className="flex flex-col">
        <div className="-my-2">{children}</div>
      </div>
    </div>
  </main>
);

export const PaddedContent: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="min-w-full px-4 py-2 align-middle lg:px-8">{children}</div>
);

export default MainContent;
