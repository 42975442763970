import { ChartBarIcon, ExternalLinkIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../context/user-context';
import api from '../lib/api';
import AthleteTeams from './athlete-teams';
import NationalityFlag from './nationality-flag';
import ReactMarkdown from 'react-markdown';
import { ITRAIcon, InstagramIcon, StravaIcon, UTMBIcon, UltraSignupIcon, WMRAIcon } from './icons';
import { DEFAULT_PROFILE_IMAGE_URL } from '../lib/constants';

interface AthleteProfileModalProps {
  athleteId?: number;
  firstName: string;
  lastName: string;
  location: string;
  notes?: string;
  onClose: () => void;
  resultsYear?: number;
  resultId?: number;
  itraId?: string;
  utmbId?: string;
}

const AthleteProfileModal = ({
  athleteId,
  firstName,
  lastName,
  location,
  notes = undefined,
  onClose,
  resultsYear = undefined,
  resultId = undefined,
  itraId = undefined,
  utmbId = undefined
}: AthleteProfileModalProps) => {
  const user = useContext(UserContext);
  const [athlete, setAthlete] = useState(null);
  const [showTeamTenures, setShowTeamTenures] = useState(false);

  const canonicalFirstName = athlete?.first_name || firstName;
  const canonicalLastName = athlete?.last_name || lastName;

  useEffect(() => {
    if (athleteId) {
      api(user.accessToken)
        .getAthlete(athleteId, resultsYear)
        .then((response) => {
          response.json().then((body) => {
            setAthlete(body.data);
          });
        });
    }
  }, [athleteId]);

  if (athleteId && !athlete) {
    return null;
  }

  const runStats = athlete?.strava_stats?.run_summary;
  const canonicalItraId = athlete?.itra_id || itraId;
  const canonicalUtmbUrl =
    athlete?.utmb_url || (utmbId ? `https://utmb.world/en/runner/${utmbId}` : undefined);

  return (
    <dialog id="athleteModal" className="modal modal-open">
      <div className="modal-box from-base-300/30 to-base-200/20 relative h-auto max-w-lg bg-gradient-to-br p-0">
        <div
          className="relative w-full overflow-hidden"
          style={{
            backgroundImage:
              'url(https://d2gwq01zobnbgs.cloudfront.net/assets/content/athletes/hero-evergreen.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <div className="relative flex items-start justify-between p-4 pb-3">
            <div className="flex justify-between">
              {Boolean(athlete?.profile_image_urls?.thumb) ? (
                <img
                  src={athlete?.profile_image_urls?.thumb || DEFAULT_PROFILE_IMAGE_URL}
                  className="mr-3 h-14 w-14 rounded-full shadow-lg"
                />
              ) : (
                <div
                  className={`bg-base-300/60 mr-3 inline-flex h-14 w-14 items-center justify-center rounded-full`}
                >
                  <span className="text-lg">
                    {[canonicalFirstName, canonicalLastName]
                      .filter((n) => n)
                      .map((n) => n[0].toUpperCase())
                      .join('')}
                  </span>
                </div>
              )}
              <div>
                <h3 className="mb-1 flex items-center text-lg font-medium">
                  <span className="mr-3">
                    {canonicalFirstName} {canonicalLastName}
                  </span>
                  <NationalityFlag country={athlete?.country} />
                </h3>
                <div className="mt-1 flex items-center text-sm font-normal capitalize sm:mt-0">
                  <LocationMarkerIcon
                    className="text-secondary mr-1 h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  {athlete?.location || location}
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-circle btn-ghost btn-sm text-primary-content/90 absolute right-2 top-2"
              onClick={onClose}
            >
              ✕
            </button>
          </div>
          <div className="bg-base-100/80 mt-3 grid grid-cols-2 px-2 py-2 sm:grid-cols-3">
            {Boolean(athlete?.personal_url) && (
              <div className="flex items-center p-3">
                <a
                  href={athlete?.personal_url}
                  target="_blank"
                  title={athlete?.personal_url.replace(/(^\w+:|^)\/\//, '')}
                  className="hyperlink flex items-center"
                >
                  <img
                    src={athlete?.profile_image_urls?.thumb}
                    className="mr-2 h-5 w-5 rounded-full"
                  />
                  <span className="text-sm">Personal</span>
                </a>
              </div>
            )}
            <div className="flex items-center p-3">
              <a
                href={
                  athlete?.ultrasignup_url ||
                  `https://ultrasignup.com/results_participant.aspx?fname=${canonicalFirstName}&lname=${canonicalLastName}`
                }
                target="_blank"
                className="hyperlink flex items-center"
                title="UltraSignup"
              >
                <UltraSignupIcon className="mr-2" />
                <span className="text-sm">UltraSignup</span>
              </a>
            </div>
            <div className="flex items-center p-3">
              <a
                href={
                  athlete?.duv_url ||
                  `https://statistik.d-u-v.org/searchrunner.php?sname=${canonicalLastName}%2C+${canonicalFirstName}&Submit.x=0&Submit.y=0`
                }
                target="_blank"
                className="hyperlink flex items-center"
                title="DUV Stats"
              >
                <ChartBarIcon className="mr-2 h-5 w-5" />
                <span className="text-sm">DUV Statistics</span>
              </a>
            </div>
            {Boolean(canonicalItraId) && (
              <div className="flex items-center p-3">
                <a
                  href={`https://itra.run/RunnerSpace/${canonicalItraId}`}
                  target="_blank"
                  className="hyperlink flex items-center"
                  title="ITRA"
                >
                  <ITRAIcon className="mr-2" />
                  <span className="text-sm">ITRA</span>
                </a>
              </div>
            )}
            {Boolean(canonicalUtmbUrl) && (
              <div className="flex items-center p-3">
                <a
                  href={canonicalUtmbUrl}
                  target="_blank"
                  className="hyperlink flex items-center"
                  title="UTMB"
                >
                  <UTMBIcon className="mr-2" />
                  <span className="text-sm">UTMB</span>
                </a>
              </div>
            )}
            {Boolean(athlete?.wmra_url) && (
              <div className="flex items-center p-3">
                <a
                  href={athlete.wmra_url}
                  target="_blank"
                  className="hyperlink flex items-center"
                  title="WMRA"
                >
                  <WMRAIcon className="mr-2" />
                  <span className="text-sm">WMRA</span>
                </a>
              </div>
            )}
            {Boolean(athlete?.strava_url) && (
              <div className="flex items-center p-3">
                <a
                  href={athlete.strava_url}
                  target="_blank"
                  className="hyperlink flex items-center"
                  title="Strava"
                >
                  <StravaIcon className="mr-2" />
                  <span className="text-sm">Strava</span>
                </a>
              </div>
            )}
            {Boolean(athlete?.instagram_handle) && (
              <div className="flex items-center p-3">
                <a
                  href={`https://instagram.com/${athlete.instagram_handle}`}
                  target="_blank"
                  className="hyperlink flex items-center"
                  title="Instagram"
                >
                  <InstagramIcon className="mr-2" />
                  <span className="text-sm">@{athlete.instagram_handle}</span>
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="space-y-6 p-4 pb-8">
          {Boolean(notes) && (
            <div>
              <h2 className="mb-4">Notes</h2>
              <ReactMarkdown className="markdown text-xs leading-5" linkTarget="_blank">
                {notes}
              </ReactMarkdown>
            </div>
          )}
          {Boolean(runStats) && (
            <div>
              <h2 className="mb-4">Training</h2>
              <p className="mb-8 text-xs">Stats summarize last four weeks of running.</p>
              <table className="mb-2 mt-2 w-full table-auto text-sm">
                <thead className="border-b border-gray-300 text-left font-mono font-medium uppercase">
                  <tr>
                    <th></th>
                    <th className="font-medium">Weekly Avg</th>
                    <th className="font-medium">Total</th>
                  </tr>
                </thead>
                <tbody className="text-xs">
                  <tr>
                    <td className="pr-2 pt-4 font-mono text-sm font-medium uppercase">Distance</td>
                    <td className="pr-2 pt-4">
                      {runStats.avg_distance_miles_s} ({runStats.avg_distance_km_s})
                    </td>
                    <td className="pr-2 pt-4">
                      {runStats.distance_miles_s} ({runStats.distance_km_s})
                    </td>
                  </tr>
                  <tr>
                    <td className="pr-2 pt-4 font-mono text-sm font-medium uppercase">Vert</td>
                    <td className="pr-2 pt-4">
                      {runStats.avg_elevation_gain_ft_s} ({runStats.avg_elevation_gain_meters_s})
                    </td>
                    <td className="pr-2 pt-4">
                      {runStats.elevation_gain_ft_s} ({runStats.elevation_gain_meters_s})
                    </td>
                  </tr>
                  <tr>
                    <td className="pr-2 pt-4 font-mono text-sm font-medium uppercase">Time</td>
                    <td className="pr-2 pt-4">{runStats.avg_elapsed_time_s}</td>
                    <td className="pr-2 pt-4">{runStats.elapsed_time_s}</td>
                  </tr>
                </tbody>
              </table>
              <div className="flex place-content-between">
                <p className="pt-[20px] text-xs text-gray-700">
                  <a href={athlete?.strava_url} className="hyperlink underline" target="_blank">
                    View on Strava
                  </a>
                </p>
                <img
                  className="h-[40px]"
                  src="https://d2gwq01zobnbgs.cloudfront.net/assets/strava/api_logo_pwrdBy_strava_stack_light.svg"
                />
              </div>
            </div>
          )}
          {Boolean(athlete?.teams?.length || athlete?.team_tenures?.length) && (
            <div>
              <h2 className="mb-4">Teams</h2>
              <div className="flex flex-col items-start space-y-4">
                {Boolean(athlete?.teams?.length) && (
                  <div>
                    <AthleteTeams teams={athlete.teams} />
                  </div>
                )}
                {Boolean(athlete?.team_tenures?.length) && (
                  <>
                    <button
                      className="hyperlink block text-xs underline"
                      onClick={() => setShowTeamTenures(!showTeamTenures)}
                    >
                      {showTeamTenures ? 'Hide' : 'View'} team history
                    </button>
                    {showTeamTenures && (
                      <table className="mt-4 w-full table-auto text-sm">
                        <thead className="border-b border-gray-300 text-left font-mono uppercase">
                          <tr>
                            <th>Team</th>
                            <th>Start Year</th>
                            <th>End Year</th>
                          </tr>
                        </thead>
                        <tbody className="text-xs">
                          {athlete.team_tenures.map((team_tenure) => (
                            <tr key={team_tenure.id}>
                              <td className="pr-2 pt-4">{team_tenure.team.name}</td>
                              <td className="whitespace-nowrap pr-2 pt-4">
                                {team_tenure.start_year || '-'}
                              </td>
                              <td className="whitespace-nowrap pr-2 pt-4">
                                {team_tenure.end_year || '-'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {(athlete?.results || []).length > 0 && Boolean(resultsYear) && (
            <div>
              <h2 className="mb-4">{Boolean(resultId) ? 'Result' : 'Results'}</h2>
              <div>
                <table className="w-full table-auto text-sm">
                  <thead className="border-b border-gray-300 text-left font-mono uppercase">
                    <tr>
                      <th>Date</th>
                      <th>Race</th>
                      <th className="py-2 pr-2">Place</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody className="text-xs">
                    {athlete.results
                      .filter((r) => !resultId || r.id === resultId)
                      .map((result) => (
                        <tr key={result.id}>
                          <td className="whitespace-nowrap pr-2 pt-4">
                            {result.race.formatted_date}
                          </td>
                          <td className="pr-2 pt-4">
                            <a
                              target="_blank"
                              href={result.race.results_url}
                              className="hyperlink underline"
                            >
                              {result.race.name}
                            </a>
                          </td>
                          <td className="whitespace-nowrap pr-2 pt-4">
                            {!result.dnf ? `${result.place_field}` : 'DNF'}
                            {result.course_record && <span className="ml-2">👑</span>}
                          </td>
                          <td className="whitespace-nowrap pt-4">
                            {result.dnf ? '-' : result.formatted_finish_time}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {(athlete.results || []).some(
                  (r) => (!resultId || resultId == r.id) && r.course_record
                ) && (
                  <p className="mt-4 border-t border-gray-300 pt-4 text-xs">
                    <span className="mr-1">👑</span>
                    <span className="italic">Course Record</span>
                  </p>
                )}
              </div>
            </div>
          )}
          {Boolean(athlete?.athlete_media?.length) && (
            <div>
              <h2 className="mb-4">Media</h2>
              <ul>
                {(athlete.athlete_media || []).map((media) => (
                  <li className="mb-3 last:mb-0" key={media.id}>
                    <ExternalLinkIcon className="mr-1 inline h-4 w-4" />
                    <a href={media.url} target="_blank" className="hyperlink text-xs underline">
                      {media.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={onClose}>close</button>
      </form>
    </dialog>
  );
};

export default AthleteProfileModal;
