import * as React from 'react';
import { PropsWithChildren } from 'react';
import { EventSponsor } from '../lib/model';

interface PageHeaderProps {
  title: any;
  subTitle?: any;
}

const PageHeader = ({ title, subTitle }: PageHeaderProps) => (
  <header>
    <div className="dark:from-base-100 dark:to-base-200 to-base-200 from-base-200/50 mx-auto justify-between bg-gradient-to-r px-2 py-8 sm:px-8">
      <div className="mx-auto flex max-w-screen-2xl flex-wrap px-8">
        <div className="flex-1">
          <h1 className="text-2xl font-medium tracking-tight sm:text-3xl">{title}</h1>
          {subTitle && <h2 className="mt-3 text-base text-gray-600">{subTitle}</h2>}
        </div>
      </div>
    </div>
  </header>
);

export default PageHeader;
