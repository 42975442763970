import React from 'react';
import Footer from '../components/footer';
import PageHeader from '../components/page-header';
import MainContent, { PaddedContent } from '../components/main-content';
import { CheckIcon } from '@heroicons/react/solid';

const FAQ = () => {
  return (
    <>
      <PageHeader title="FAQs" subTitle="Frequently asked questions about the platform" />
      <MainContent>
        <PaddedContent>
          <div className="prose prose-sm max-w-3xl">
            <h2>What is an event series?</h2>
            <p>
              An event series consists of multiple related events. Picks for each event are scored
              independently, and a separate leaderboard is displayed on the series page to show who
              scored best cumulatively across all events.
            </p>

            <h2>How are my picks scored?</h2>
            <p>
              Picks are scored using simple point systems. These are outlined in the tables below. A
              player's <span className="font-medium">Total Score</span> is computed by summing their
              scores for each field. In the event that multiple participants achieve the same score,
              the time at which their picks were finalized serves as the tie breaker, with the first
              to finalize their picks winning out.
            </p>
            <p className="font-medium">Last updated: December 30, 2024</p>

            <h3>Five Picks Per Field</h3>
            <p>
              You are awarded points for accuracy if your pick finishes in the top 10 in their
              field, with pick #1 accuracy being most valuable and pick #5 accuracy being least
              valuable.{' '}
              <span className="font-medium">
                If your pick finishes outside of the top 10, you are not awarded any points.
              </span>
            </p>

            <table className="table">
              <thead>
                <tr>
                  <th>Your Pick</th>
                  <th>Scoring Function</th>
                  <th>Point Range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>(10 - ABS(1 - [actual finishing position])) * 50</td>
                  <td>50 - 500</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>(10 - ABS(2 - [actual finishing position])) * 48</td>
                  <td>96 - 480</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>(10 - ABS(3 - [actual finishing position])) * 46</td>
                  <td>138 - 460</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>(10 - ABS(4 - [actual finishing position])) * 44</td>
                  <td>176 - 440</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>(10 - ABS(5 - [actual finishing position])) * 42</td>
                  <td>210 - 420</td>
                </tr>
                <tr className="mt-2 border-t border-gray-400">
                  <td></td>
                  <td className="font-medium">Possible Pre-Bonus Points Per Field</td>
                  <td className="font-medium">2300</td>
                </tr>
              </tbody>
            </table>

            <h3>Ten Picks Per Field</h3>
            <p>
              You are awarded points for accuracy if your pick finishes in the top 15 in their
              field, with pick #1 accuracy being most valuable and pick #10 accuracy being least
              valuable.{' '}
              <span className="font-medium">
                If your pick finishes outside of the top 15, you are not awarded any points.
              </span>
            </p>

            <table className="table-zebra table">
              <thead>
                <tr>
                  <th>Your Pick</th>
                  <th>Scoring Function</th>
                  <th>Point Range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>(15 - ABS(1 - [actual finishing position])) * 50</td>
                  <td>50 - 750</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>(15 - ABS(2 - [actual finishing position])) * 48</td>
                  <td>96 - 720</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>(15 - ABS(3 - [actual finishing position])) * 46</td>
                  <td>138 - 690</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>(15 - ABS(4 - [actual finishing position])) * 44</td>
                  <td>176 - 660</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>(15 - ABS(5 - [actual finishing position])) * 42</td>
                  <td>210 - 630</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>(15 - ABS(6 - [actual finishing position])) * 40</td>
                  <td>240 - 600</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>(15 - ABS(7 - [actual finishing position])) * 38</td>
                  <td>266 - 570</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>(15 - ABS(8 - [actual finishing position])) * 36</td>
                  <td>288 - 540</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>(15 - ABS(9 - [actual finishing position])) * 34</td>
                  <td>238 - 510</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>(15 - ABS(10 - [actual finishing position])) * 32</td>
                  <td>192 - 480</td>
                </tr>
                <tr className="mt-2 border-t border-gray-400">
                  <td></td>
                  <td className="font-medium">Possible Pre-Bonus Points Per Field</td>
                  <td className="font-medium">6150</td>
                </tr>
              </tbody>
            </table>

            <h3 className="flex items-center">
              Bonus Points <span className="badge badge-primary ml-2 font-medium">NEW</span>
            </h3>
            <p>
              You are awarded bonus points for certain achievements. These are outlined in the table
              below. The <span className="font-medium">Pick Five</span> and{' '}
              <span className="font-medium">Pick Ten</span> columns indicate for which events these
              bonuses are available.
            </p>

            <table className="table-zebra table">
              <thead>
                <tr>
                  <th>Achievement</th>
                  <th>Points Awarded</th>
                  <th>Pick Five</th>
                  <th>Pick Ten</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Multiple exact matches</td>
                  <td>(# of exact matches * 5)^2</td>
                  <td>
                    <CheckIcon className="h-4 w-4" />
                  </td>
                  <td>
                    <CheckIcon className="h-4 w-4" />
                  </td>
                </tr>
                <tr>
                  <td>Top 3 in any order</td>
                  <td>150</td>
                  <td>
                    <CheckIcon className="h-4 w-4" />
                  </td>
                  <td>
                    <CheckIcon className="h-4 w-4" />
                  </td>
                </tr>
                <tr>
                  <td>Top 3 exact match</td>
                  <td>300</td>
                  <td>
                    <CheckIcon className="h-4 w-4" />
                  </td>
                  <td>
                    <CheckIcon className="h-4 w-4" />
                  </td>
                </tr>
                <tr>
                  <td>Top 5 in any order</td>
                  <td>250</td>
                  <td>
                    <CheckIcon className="h-4 w-4" />
                  </td>
                  <td>
                    <CheckIcon className="h-4 w-4" />
                  </td>
                </tr>
                <tr>
                  <td>Top 5 exact match</td>
                  <td>500</td>
                  <td></td>
                  <td>
                    <CheckIcon className="h-4 w-4" />
                  </td>
                </tr>
                <tr>
                  <td>Top 10 in any order</td>
                  <td>500</td>
                  <td></td>
                  <td>
                    <CheckIcon className="h-4 w-4" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default FAQ;
