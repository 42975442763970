import React, { PropsWithChildren, useState } from 'react';

type FormProps = {
  onSubmit: () => void;
  disabled?: boolean;
  submitButtonText?: string;
};

const Form = ({
  onSubmit,
  disabled = false,
  submitButtonText = 'Submit',
  children
}: PropsWithChildren<FormProps>) => {
  const [isSaving, setIsSaving] = useState(false);
  const [showSaved, setShowSaved] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSaving(true);

    try {
      await onSubmit();
      setIsSaving(false);
      setShowSaved(true);
      setTimeout(() => setShowSaved(false), 1500);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {children}
      <div className="flex items-center justify-start gap-x-6">
        <button type="submit" disabled={disabled || isSaving} className="btn btn-primary">
          {isSaving ? 'Saving...' : submitButtonText}
        </button>
        {showSaved && <span className="badge badge-primary ml-2 text-xs">Saved!</span>}
      </div>
    </form>
  );
};

export default Form;
