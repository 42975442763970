import React from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import NationalityFlag from './nationality-flag';
import AthleteTeams from './athlete-teams';
import { InstagramIcon, StravaIcon, UTMBIcon, UltraSignupIcon } from './icons';

interface Athlete {
  id: number;
  profile_slug: string;
  first_name: string;
  last_name: string;
  profile_image_urls: { thumb: string };
  country: string;
  location: string;
  teams: any[];
  ultrasignup_url: string;
  utmb_url: string;
  strava_url: string;
  instagram_handle: string;
}

interface AthletesTableProps {
  athletes: Athlete[];
  featuredAthlete: Athlete | null;
}

const AthletesTable: React.FC<AthletesTableProps> = ({ athletes, featuredAthlete }) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: 'Name',
      id: 'athlete-name',
      selector: (row) => `${row.first_name} ${row.last_name}`,
      cell: (row) => (
        <div className="flex items-center">
          <img
            className="mr-3 h-10 w-10 rounded-full"
            data-tag="allowRowEvents"
            src={row.profile_image_urls.thumb}
            alt={`${row.first_name} ${row.last_name}`}
          />
          <span data-tag="allowRowEvents">
            {row.first_name} {row.last_name}
          </span>
        </div>
      ),
      sortable: true,
      minWidth: '200px'
    },
    {
      name: 'Nationality',
      cell: (row) => <NationalityFlag country={row.country} />,
      selector: (row) => row.country,
      center: true,
      sortable: true
    },
    {
      name: 'Location',
      selector: (row) => row.location,
      sortable: true
    },
    {
      name: 'Team',
      cell: (row) => <AthleteTeams teams={row.teams} showOne />,
      maxWidth: '100px'
    },
    {
      name: 'UltraSignup',
      cell: (row) => (
        <a
          href={
            row.ultrasignup_url ||
            `https://ultrasignup.com/results_participant.aspx?fname=${row.first_name}&lname=${row.last_name}`
          }
          target="_blank"
          className="hyperlink underline"
          onClick={(e) => e.stopPropagation()}
        >
          <UltraSignupIcon />
        </a>
      ),
      center: true,
      maxWidth: '150px'
    },
    {
      name: 'UTMB',
      cell: (row) =>
        Boolean(row.utmb_url) && (
          <a
            href={row.utmb_url}
            target="_blank"
            className="hyperlink underline"
            onClick={(e) => e.stopPropagation()}
          >
            <UTMBIcon />
          </a>
        ),
      center: true,
      maxWidth: '150px'
    },
    {
      name: 'Strava',
      cell: (row) =>
        Boolean(row.strava_url) && (
          <a
            href={row.strava_url}
            target="_blank"
            className="hyperlink"
            onClick={(e) => e.stopPropagation()}
          >
            <StravaIcon />
          </a>
        ),
      center: true,
      maxWidth: '150px'
    },
    {
      name: 'Instagram',
      cell: (row) =>
        Boolean(row.instagram_handle) && (
          <a
            href={`https://instagram.com/${row.instagram_handle}`}
            target="_blank"
            className="hyperlink underline"
            onClick={(e) => e.stopPropagation()}
          >
            <InstagramIcon />
          </a>
        ),
      center: true,
      maxWidth: '150px'
    }
  ];

  return (
    <DataTable
      columns={columns}
      data={[featuredAthlete, ...athletes].filter((a) => a)}
      pagination
      paginationPerPage={50}
      defaultSortFieldId={featuredAthlete ? 0 : 'athlete-name'}
      onRowClicked={(row) => navigate(`/athletes/${row?.profile_slug}`)}
      className="athletes-table data-table base-table"
      noDataComponent={
        <div className="bg-base-200 text-base-content my-8 text-center text-sm">
          No athletes found
        </div>
      }
      customStyles={{}}
    />
  );
};

export default AthletesTable;
