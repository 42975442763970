import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../lib/api';
import PageHeader from '../components/page-header';
import UserContext from '../context/user-context';
import Footer from '../components/footer';
import Loading from '../components/loading';
import { Entrant, EventRaceResults } from '../lib/model';
import MainContent, { PaddedContent } from '../components/main-content';
import NationalityFlag from '../components/nationality-flag';
import SeasonSelect from '../components/season-select';
import ReactMarkdown from 'react-markdown';
import { AthletesSubNav } from '../components/nav';
import { CheckIcon } from '@heroicons/react/solid';

const getYearEvents = (year: number): [string | undefined, string | undefined] => {
  let yearEvents: { [year: number]: [string, string | undefined] };

  if (process.env.NODE_ENV === 'production') {
    yearEvents = {
      2024: ['e8d6faf1-f8da-45ba-9e1a-3f4f23ad0d4a', 'b793d867-6aa3-46b0-9373-cdb0a1870f08'],
      2023: ['a629cca7-dfed-4864-a1b0-de1fe7a33615', '5fc29923-eb91-4f7e-a4f8-fc47fdb46e08'],
      2022: ['0f829653-f1b6-4fde-8875-701b71922827', undefined]
    };
  } else {
    yearEvents = {
      2023: ['e293a20d-a819-48ef-a264-2d8e15472292', '60960d2f-69c5-461b-8465-93ee66daba1f'],
      2022: ['e293a20d-a819-48ef-a264-2d8e15472292', undefined]
    };
  }

  if (!yearEvents.hasOwnProperty(year)) {
    return [undefined, undefined];
  }

  return yearEvents[year];
};

const RunnerCard = ({ runner }: { runner: Entrant }) => (
  <div key={runner.athlete_id} className="mb-6 flex items-center justify-between rounded-lg">
    <div className="flex items-start">
      <img
        src={runner.athlete?.profile_image_urls?.thumb}
        alt=""
        className="mr-4 h-16 w-16 rounded-full"
      />
      <span className="mr-4 text-4xl font-extrabold">
        <span className="inline-block px-2">{runner.post_race_rank}</span>
      </span>
      <div className="flex flex-col space-y-2">
        <Link to={`/athletes/${runner.athlete!!.profile_slug}`} className="font-bold">
          {runner.first_name} {runner.last_name}
        </Link>
        {Boolean(runner.result) && (
          <span className="text-sm font-medium">{runner.result!!.race!!.name}</span>
        )}
        <div className="text-sm">
          <NationalityFlag country={runner.athlete?.country} showCountryName />
        </div>
        {Boolean(runner.notes) && (
          <div className="mt-1">
            <ReactMarkdown
              children={runner.notes!!}
              className="markdown text-xs leading-5"
              linkTarget="_blank"
            />
          </div>
        )}
      </div>
    </div>
  </div>
);

const TROY = () => {
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [runnerEventId, setRunnerEventId] = useState<string | undefined>(undefined);
  const [perfEventId, setPerfEventId] = useState<string | undefined>(undefined);
  const [runnerResults, setRunnerResults] = useState<EventRaceResults | undefined>(undefined);
  const [perfResults, setPerfResults] = useState<EventRaceResults | undefined>(undefined);
  const params = useParams() as { year: string };
  const year = parseInt(params.year);
  const navigate = useNavigate();

  useEffect(() => {
    const [_runnerEventId, _perfEventId] = getYearEvents(year);

    const fetchResults = async () => {
      if (_runnerEventId) {
        const troyRunnerResults = await api(user.accessToken).getEventRaceResults(_runnerEventId);
        setRunnerResults(troyRunnerResults);
      } else {
        setRunnerResults(undefined);
      }

      if (_perfEventId) {
        const troyPerfResults = await api(user.accessToken).getEventRaceResults(_perfEventId);
        setPerfResults(troyPerfResults);
      } else {
        setPerfResults(undefined);
      }

      setRunnerEventId(_runnerEventId);
      setPerfEventId(_perfEventId);

      setIsLoading(false);
    };

    fetchResults();
  }, [user.isAuthenticated, year]);

  return (
    <>
      <AthletesSubNav />
      <PageHeader
        title={`Trail Runner of the Year`}
        subTitle="View the results of our annual TROY awards"
      />
      <MainContent>
        <PaddedContent>
          <div className="grid grid-cols-1 gap-x-8 lg:grid-cols-2">
            <div>
              <h2 className="mb-4 text-xl font-semibold">Vision</h2>
              <p className="pb-8 text-sm leading-relaxed">
                Like many global professional sports, trail running deserves a year-end distinction
                for those athletes whose performances have stood out on the international racing
                circuit. Trail Runner of the Year (TROY) is an extension of Freetrail’s mission to
                elevate the profile of the professional athletes in our sport, while helping casual
                observers and the general public feel connected to their stories – hopefully
                creating diehard fans in the process.
              </p>
            </div>
            <div>
              <h2 className="mb-4 text-xl font-semibold">Parameters</h2>
              <p className="pb-8 text-sm leading-relaxed">
                <ul className="list-outside">
                  <li className="flex items-center">
                    <CheckIcon className="mr-2 h-4 w-4" />
                    Evaluation of race performance
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="mr-2 h-4 w-4" />
                    Scoped to the calendar year
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="mr-2 h-4 w-4" />
                    Trail running specific
                  </li>
                  <li className="flex items-center">
                    <CheckIcon className="mr-2 h-4 w-4" />
                    Distance agnostic
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <SeasonSelect
            onChange={({ value }) => navigate(`/troy/${value}`)}
            year={year}
            maxYear={new Date().getFullYear() - 1}
          />
          {isLoading ? (
            <Loading />
          ) : runnerResults === undefined ? (
            <div className="mt-12">
              <p className="text-sm">No results available for this year.</p>
            </div>
          ) : (
            <div className="mt-12 grid grid-cols-1 lg:grid-cols-1">
              <div>
                <h2 className="mb-4 text-xl font-semibold">Trail Runner of the Year</h2>
                <Link
                  to={`/events/${runnerEventId}`}
                  className="hyperlink mb-6 block text-sm underline"
                >
                  View Nominations
                </Link>
                <div className="border-base-300 mb-6 border-b" />
                <div className="grid grid-cols-1 gap-x-8 sm:grid-cols-2">
                  <div>
                    <h3 className="mb-6 font-medium uppercase">Women</h3>
                    {runnerResults.female_results.map((entrant) => (
                      <RunnerCard key={entrant.id} runner={entrant} />
                    ))}
                  </div>
                  <div>
                    <h3 className="mb-6 font-medium uppercase">Men</h3>
                    {runnerResults.male_results.map((entrant) => (
                      <RunnerCard key={entrant.id} runner={entrant} />
                    ))}
                  </div>
                </div>
              </div>
              {perfResults !== undefined && (
                <div>
                  <h2 className="mb-4 mt-6 text-xl font-semibold">Trail Performance of the Year</h2>
                  <Link
                    to={`/events/${perfEventId}`}
                    className="hyperlink mb-6 block text-sm underline"
                  >
                    View Nominations
                  </Link>
                  <div className="border-base-300 mb-6 border-b" />
                  <div className="grid grid-cols-1 gap-x-8 sm:grid-cols-2">
                    <div>
                      <h3 className="mb-6 font-medium uppercase">Women</h3>
                      {perfResults.female_results.map((entrant) => (
                        <RunnerCard key={entrant.id} runner={entrant} />
                      ))}
                    </div>
                    <div>
                      <h3 className="mb-6 font-medium uppercase">Men</h3>
                      {perfResults.male_results.map((entrant) => (
                        <RunnerCard key={entrant.id} runner={entrant} />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default TROY;
