import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Footer from '../components/footer';
import Loading from '../components/loading';
import UserContext from '../context/user-context';
import api from '../lib/api';
import MainContent from '../components/main-content';
import { League } from '../lib/model';
import { loginAndRedirect } from '../lib/auth';
import { useAuth0 } from '@auth0/auth0-react';

const LeagueInviteRoute: React.FC = () => {
  const params = useParams() as { inviteCode: string };
  const user = useContext(UserContext);
  const { loginWithRedirect } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [league, setLeague] = useState<League | undefined>(undefined);

  useEffect(() => {
    if (!user.accessToken) {
      setIsLoading(false);
      return;
    }

    const fetchLeague = async () => {
      try {
        const leagueData = await api(user.accessToken).getLeague(params.inviteCode);
        setIsLoading(false);
        setLeague(leagueData);
      } catch (e) {
        setIsLoading(false);
        setNotFound(true);
      }
    };

    fetchLeague();
  }, [user.isAuthenticated]);

  const joinLeague = useCallback(async () => {
    setIsJoining(true);
    await api(user.accessToken).joinLeague(league!.uuid);
    setIsJoining(false);
    window.location.href = `/leagues/${league!.uuid}?joined=true`;
  }, [league?.uuid]);

  let content: JSX.Element;

  if (!user.isAuthenticated && !isLoading) {
    content = (
      <div className="min-w-full px-4 py-28 text-center align-middle lg:px-8">
        <p className="text-xl font-medium">Please sign in to accept this invite.</p>
        <a
          href="#"
          className="btn btn-primary mt-8"
          onClick={() => loginAndRedirect(loginWithRedirect)}
        >
          Sign In
        </a>
      </div>
    );
  } else if (notFound) {
    content = (
      <div className="min-w-full px-4 py-28 text-center align-middle lg:px-8">
        <p className="text-xl font-medium text-black">This invite code is invalid.</p>
        <Link to="/events" className="bg-lazer-lemon-500 mt-8 inline-block px-6 py-4 font-mono">
          Back to Events
        </Link>
      </div>
    );
  } else if (league === undefined) {
    content = <Loading />;
  } else {
    content = (
      <div className="min-w-full px-4 py-28 text-center align-middle lg:px-8">
        <img src={league.avatar_url} className="mx-auto mb-8 h-32 w-32 rounded" />
        <p className="text-xl font-medium">{league.name}</p>
        {league.current_user_member ? (
          <>
            <p className="mt-6 text-lg text-gray-500">You are already a member of this league.</p>
            <Link to={`/leagues/${league.uuid}`} className="btn btn-primary mt-8">
              View League
            </Link>
          </>
        ) : (
          <>
            <p className="mt-6 text-lg text-gray-500">
              Click the button below to join this league.
            </p>
            <button
              onClick={() => joinLeague()}
              className="btn btn-primary mt-8"
              disabled={isJoining}
            >
              Join League
            </button>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <MainContent>{content}</MainContent>
      <Footer />
    </>
  );
};

export default LeagueInviteRoute;
