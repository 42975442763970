import React, { useState } from 'react';

const CopyPicksButton = ({ event, femalePicks, malePicks, isCommunity, emphasize }) => {
  const [picksCopied, setPicksCopied] = useState(false);

  const copyPicksToClipboard = () => {
    let whose = isCommunity ? 'the @runfreetrail community' : 'my @runfreetrail';
    let text = `Check out ${whose} picks for ${event.name_and_format}!\n`;

    const emojiForIndex = (i) => {
      switch (i) {
        case 0:
          return '🥇';
        case 1:
          return '🥈';
        case 2:
          return '🥉';
        case 3:
          return '4️⃣';
        case 4:
          return '5️⃣';
        case 5:
          return '6️⃣';
        case 6:
          return '7️⃣';
        case 7:
          return '8️⃣';
        case 8:
          return '9️⃣';
        case 9:
          return '🔟';
      }
    };

    const picksToText = (picks, heading) => {
      if (!picks.length) {
        return '';
      }

      return picks.slice(0, 10).reduce((text, entrant, i) => {
        return (
          text +
          `${emojiForIndex(i)} ${entrant.first_name[0].toUpperCase()}. ${entrant.last_name}` +
          (entrant.result ? ` - ${entrant.result.race.name}` : '') +
          `\n`
        );
      }, `\n${heading}:\n\n`);
    };

    text += picksToText(femalePicks, 'W');
    text += picksToText(malePicks, 'M');

    if (event.hashtag) {
      text += `\n#${event.hashtag}`;
    }

    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: text.trim()
        })
        .then(() => {})
        .catch((error) => console.log('Error sharing:', error));
    } else {
      navigator.clipboard.writeText(text.trim());
      setPicksCopied(true);
    }
  };

  if (!Boolean([...femalePicks, ...malePicks].length)) {
    return null;
  }

  return (
    <button
      className={`btn ${emphasize ? 'dark:btn-neutral btn-accent' : 'btn-ghost'}`}
      onClick={() => copyPicksToClipboard()}
    >
      {picksCopied && <span>Copied to Clipboard!</span>}
      {!picksCopied && <span>Share</span>}
    </button>
  );
};

export default CopyPicksButton;
