import React, { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink, useLocation } from 'react-router-dom';
import UserContext from '../context/user-context';
import { loginAndRedirect } from '../lib/auth';
import { MenuIcon } from '@heroicons/react/solid';
import { MoonIcon, SunIcon } from '@heroicons/react/outline';

const ThemeToggle = ({ onToggle, isDarkMode }: { onToggle: () => void; isDarkMode: boolean }) => (
  <label className="swap swap-rotate">
    {/* this hidden checkbox controls the state */}
    <input
      type="checkbox"
      className="theme-controller"
      value="freetrail-dark"
      onChange={() => onToggle()}
      checked={isDarkMode}
    />

    <SunIcon className="swap-off dark:text-base-content text-primary-content h-6 w-6" />
    <MoonIcon className="swap-on dark:text-base-content text-primary-content h-6 w-6" />
  </label>
);

const Logo = () => (
  <a
    className="btn btn-outline font-logo hover:text-primary border-none text-base hover:bg-transparent"
    href="/"
  >
    <img
      src="https://d2gwq01zobnbgs.cloudfront.net/assets/logo-square.png"
      className="invert brightness-0 dark:invert-0 dark:brightness-100 mr-1 inline-block h-6 w-6 rounded-lg"
    />
    <span className="font-logo text-white">fantasy</span>
  </a>
);

const Nav = ({ onThemeChange, isDarkMode }: { onThemeChange: () => void; isDarkMode: boolean }) => {
  const user = useContext(UserContext);
  const { loginWithRedirect, logout } = useAuth0();
  const location = useLocation();

  const sharedLinkClasses =
    'mr-2 dark:focus:bg-primary active:bg-primary focus:bg-accent focus:text-primary-content dark:text-base-content text-primary-content';
  const navLinkClassNameDesktop = ({ isActive }: { isActive: boolean }) => {
    return (
      `${sharedLinkClasses} ` +
      (isActive ? 'dark:bg-primary bg-accent text-white dark:text-primary-content' : '')
    );
  };

  useEffect(() => {
    document.querySelectorAll('.main-menu details')?.forEach((detail) => {
      detail.removeAttribute('open');
    });
    document.querySelectorAll('.main-menu .dropdown a').forEach((a) => {
      (a as HTMLElement).blur();
    });
  }, [location]);

  return (
    <nav className="dark:bg-base-200 main-menu navbar dark:border-base-100/50 bg-primary border-b border-transparent shadow-md">
      <div className="navbar-start">
        <div className="dropdown lg:hidden">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-ghost btn-circle dark:text-base-content text-primary-content"
          >
            <MenuIcon className="h-5 w-5" />
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
          >
            {user.isAuthenticated && (
              <li>
                <NavLink to="/">Dashboard</NavLink>
              </li>
            )}
            <li>
              <NavLink to="/events" end>
                Events
              </NavLink>
            </li>
            {user.isAuthenticated && (
              <li>
                <NavLink to="/leaderboards">Leaderboards</NavLink>
              </li>
            )}
            <li>
              <NavLink to="/athletes">Athletes</NavLink>
            </li>
            <li>
              <NavLink to="/stats">Stats</NavLink>
            </li>
          </ul>
        </div>
        <div className="hidden lg:block">
          <Logo />
        </div>
      </div>
      <div className="navbar-center">
        <div className="-ml-4 flex lg:hidden">
          <Logo />
        </div>
        <ul className="menu menu-horizontal hidden px-1 lg:flex">
          {user.isAuthenticated && (
            <li>
              <NavLink to="/" className={navLinkClassNameDesktop}>
                Dashboard
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to="/events" end className={navLinkClassNameDesktop}>
              Events
            </NavLink>
          </li>
          {user.isAuthenticated && (
            <li>
              <NavLink to="/leaderboards" className={navLinkClassNameDesktop}>
                Leaderboards
              </NavLink>
            </li>
          )}
          <li>
            <details>
              <summary className="text-primary-content dark:text-base-content">
                <NavLink to="/athletes">Athletes</NavLink>
              </summary>
              <ul className="p2 min-w-[130px]">
                <li>
                  <NavLink to="/athletes" end role="menuitem" id="athlete-menu-item-1">
                    Search
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/troy/${new Date().getFullYear() - 1}`}
                    role="menuitem"
                    id="athlete-menu-item-2"
                  >
                    TROY
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/athletes/teams" role="menuitem" id="athlete-menu-item-3">
                    Teams
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/athletes/free-agency/${new Date(
                      Date.now() + 7 * 24 * 60 * 60 * 1000
                    ).getFullYear()}`}
                    role="menuitem"
                    id="athlete-menu-item-4"
                  >
                    Free Agency
                  </NavLink>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details>
              <summary className="text-primary-content dark:text-base-content">
                <NavLink to="/stats">Stats</NavLink>
              </summary>
              <ul className="p2">
                <li>
                  <NavLink to="/stats/splits" role="menuitem" id="stats-menu-item-1">
                    Splits
                  </NavLink>
                </li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
      <div className="navbar-end">
        <span className="mr-2 flex items-center lg:mr-6">
          <ThemeToggle onToggle={onThemeChange} isDarkMode={isDarkMode} />
        </span>
        {user.isAuthenticated ? (
          <div className="dropdown dropdown-end">
            <div className="flex items-center">
              <span className="dark:text-base-content text-primary-content mr-2 hidden py-1 text-sm lg:inline-block">
                {user.displayName}
              </span>
              <div
                tabIndex={0}
                role="button"
                className="btn btn-circle border-none bg-transparent hover:bg-transparent sm:mr-2"
              >
                <div className="w-8 rounded-full">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={user.picture}
                    alt=""
                    referrerPolicy="no-referrer"
                  />
                </div>
              </div>
            </div>
            <ul
              className="dropdown-content menu menu-sm bg-base-100 z-[1] mt-3 w-48 rounded-lg p-2 shadow"
              tabIndex={0}
            >
              <li>
                <NavLink to="/settings">Settings</NavLink>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => logout({ returnTo: window.location.origin })}
                  role="menuitem"
                  id="user-menu-item-2"
                >
                  Sign Out
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <div>
            <a
              href="#"
              onClick={() => loginAndRedirect(loginWithRedirect)}
              className="btn btn-ghost btn-sm sm:btn-md dark:text-base-content text-primary-content mr-0 font-normal sm:mr-2"
            >
              Sign In
            </a>
          </div>
        )}
      </div>
    </nav>
  );
};

export const AthletesSubNav = () => {
  const navLinkClasses = 'block border-b-4 border-base-100 py-3 hover:border-primary';
  const navLinkClassName = ({ isActive }: { isActive: boolean }) => {
    return (
      `${navLinkClasses} ` + (isActive ? 'border-primary hover:border-b-4' : 'border-base-100')
    );
  };

  return (
    <div className="bg-base-100 text-sm sm:hidden">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="min-w-full px-4 align-middle lg:px-8">
          <div className="grid grid-cols-4 text-center">
            <NavLink to="/athletes" end className={navLinkClassName}>
              Search
            </NavLink>
            <NavLink to={`/troy/${new Date().getFullYear() - 1}`} className={navLinkClassName}>
              TROY
            </NavLink>
            <NavLink to="/athletes/teams" className={navLinkClassName}>
              Teams
            </NavLink>
            <NavLink
              to={`/athletes/free-agency/${new Date(
                Date.now() + 7 * 24 * 60 * 60 * 1000
              ).getFullYear()}`}
              className={navLinkClassName}
            >
              Moves
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
