import React from 'react';
import { withOrdinalSuffix } from '../lib/strings';
import { StarIcon, EmojiHappyIcon, EmojiSadIcon, GiftIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { FIELD_FEMALE, FIELD_MALE } from '../lib/model';
import { DEFAULT_PROFILE_IMAGE_URL } from '../lib/constants';

const ScorecardModal = ({ onClose, eventUser, event, communitySize }) => {
  console.log(eventUser.score_data);

  return (
    <div className="modal modal-open">
      <div className="modal-box bg-base-200 relative max-w-lg">
        <div className="mb-4 flex items-start justify-between">
          <div className="flex justify-between">
            <img
              src={event.image_url || DEFAULT_PROFILE_IMAGE_URL}
              className="mr-3 h-14 w-14 rounded-full border border-white bg-white"
            />
            <div>
              <h3 className="dark:text-shadow-sm shadow-black/30 text-lg font-semibold">
                {event.name_and_format}
              </h3>
              <h4 className="dark:text-shadow-sm shadow-black/30 my-1 flex items-center text-sm font-normal">
                <img src={DEFAULT_PROFILE_IMAGE_URL} className="mr-[7px] inline h-4 w-4" /> Fantasy
                Scorecard
              </h4>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-circle btn-ghost btn-sm absolute right-2 top-2"
            onClick={onClose}
          >
            ✕
          </button>
        </div>
        <div className="space-y-6">
          <p className="text-sm leading-relaxed">
            You placed <span className="font-medium">{withOrdinalSuffix(eventUser.rank)}</span> out
            of {communitySize} players, with a total score of{' '}
            <span className="font-medium">{eventUser.total_score.toLocaleString()}</span>.
          </p>
          {[
            [
              eventUser.picks.filter((p) => p.entrant.field === FIELD_FEMALE),
              "Women's",
              'female_score'
            ],
            [eventUser.picks.filter((p) => p.entrant.field === FIELD_MALE), "Men's", 'male_score']
          ]
            .filter(([picks]) => (picks || []).length)
            .map(([picks, label, scoreKey]) => {
              return (
                <div key={label[0]}>
                  <h2 className="text-sm font-medium">{label} Picks</h2>
                  <div className="dark:border-base-100 mt-2 overflow-x-scroll rounded-lg shadow dark:border sm:overflow-hidden">
                    <table className="min-w-full table-auto">
                      <thead className="dark:bg-base-100 bg-zinc-900 dark:text-base-content w-10 whitespace-nowrap text-center text-xs font-normal text-white">
                        <tr>
                          <th className="py-2 font-normal">Name</th>
                          <th className="py-2 font-normal">Predicted</th>
                          <th className="py-2 font-normal">Actual</th>
                          <th className="py-2 font-normal">Points</th>
                        </tr>
                      </thead>
                      <tbody className="dark:divide-base-100 divide-base-200 dark:bg-base-300 divide-y bg-white/50">
                        {picks.map((pick) => {
                          const { entrant } = pick;

                          return (
                            <tr key={pick.id} className="text-xs">
                              <td className="td-compact">
                                {pick.rank === entrant.post_race_rank ? (
                                  <StarIcon
                                    className="text-purple-haze-500 mr-2 inline h-4 w-4 flex-shrink-0"
                                    aria-hidden="true"
                                  />
                                ) : pick.score ? (
                                  <EmojiHappyIcon
                                    className="text-amber-400 mr-2 inline h-4 w-4 flex-shrink-0"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <EmojiSadIcon
                                    className="mr-2 inline h-4 w-4 flex-shrink-0 text-gray-500"
                                    aria-hidden="true"
                                  />
                                )}
                                {entrant.first_name[0]}. {entrant.last_name}
                              </td>
                              <td className="text-center">{pick.rank}</td>
                              <td className="text-center">{entrant.post_race_rank || '-'}</td>
                              <td className="text-center">{pick.score}</td>
                            </tr>
                          );
                        })}
                        {(eventUser.score_data?.components || [])
                          .filter((c) => c[scoreKey] > 0 && c.name !== 'Proximity')
                          .map((c, index) => (
                            <>
                              {index === 0 && (
                                <tr className="dark:bg-base-200 bg-zinc-200">
                                  <td
                                    className="td-compact text-center text-xs font-medium"
                                    colSpan={4}
                                  >
                                    Bonuses
                                  </td>
                                </tr>
                              )}
                              <tr key={c.name} className="text-xs">
                                <td className="td-compact text-left font-medium" colSpan={3}>
                                  <GiftIcon
                                    className="text-amber-800 mr-2 inline h-4 w-4 flex-shrink-0"
                                    aria-hidden="true"
                                  />
                                  {c.name}
                                </td>
                                <td className="td-compact text-center">
                                  {c[scoreKey].toLocaleString()}
                                </td>
                              </tr>
                            </>
                          ))}

                        <tr className="text-xs">
                          <td className="td-compact text-right font-medium" colSpan={3}>
                            Score
                          </td>
                          <td className="td-compact text-center font-medium">
                            {eventUser[scoreKey].toLocaleString()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          <p className="text-xs leading-relaxed">
            <span className="mr-3">
              <StarIcon
                className="text-purple-haze-500 inline h-4 w-4 flex-shrink-0"
                aria-hidden="true"
              />{' '}
              Perfect
            </span>
            <span className="mr-3">
              <EmojiHappyIcon
                className="text-amber-400 inline h-4 w-4 flex-shrink-0"
                aria-hidden="true"
              />{' '}
              Points Awarded
            </span>
            <span className="mr-3">
              <EmojiSadIcon
                className="inline h-4 w-4 flex-shrink-0 text-gray-500"
                aria-hidden="true"
              />{' '}
              No Luck
            </span>
            <span className="mr-3">
              <GiftIcon
                className="text-amber-800 inline h-4 w-4 flex-shrink-0"
                aria-hidden="true"
              />{' '}
              Bonus
            </span>
          </p>
          <p className="text-xs leading-relaxed">
            Check out our{' '}
            <Link className="hyperlink underline" to="/faqs">
              FAQs page
            </Link>{' '}
            to learn about our scoring methodology.
          </p>
        </div>
      </div>
      <div className="modal-backdrop" onClick={onClose}>
        <button className="cursor-default">close</button>
      </div>
    </div>
  );
};

export default ScorecardModal;
