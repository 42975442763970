import React from 'react';
import { EventStatus } from '../lib/model';

export const STATUS_TEXT = {
  [EventStatus.Open]: 'Make Your Picks',
  [EventStatus.Closed]: 'Picks Locked In',
  [EventStatus.ResultsPosted]: 'Results Posted',
  [EventStatus.Selected]: 'On The Horizon'
};

const StatusBadge = ({ status }: { status: string }) => {
  const text = STATUS_TEXT[status as EventStatus];

  return (
    <>
      {status === EventStatus.Open && (
        <span className="dark:bg-emerald-900 dark:text-emerald-100 bg-emerald-100 text-emerald-800 inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium">
          {text}
        </span>
      )}
      {status === EventStatus.Closed && (
        <span className="dark:bg-amber-900 dark:text-amber-100 bg-amber-100 text-amber-800 inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium">
          {text}
        </span>
      )}
      {status === EventStatus.ResultsPosted && (
        <span className="dark:bg-sky-900 dark:text-sky-100 bg-sky-100 text-sky-800 inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium">
          {text}
        </span>
      )}
      {status === EventStatus.Selected && (
        <span className="dark:bg-purple-haze-900 dark:text-purple-haze-100 bg-purple-haze-100 text-purple-haze-800 inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium">
          {text}
        </span>
      )}
    </>
  );
};

export default StatusBadge;
