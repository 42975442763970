import React, { useState, useEffect, PropsWithChildren } from 'react';

interface LoadingProps {
  margin?: boolean;
}

const Loading = ({ margin }: PropsWithChildren<LoadingProps>) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => (prevProgress + 1) % 100);
    }, 10);

    return () => clearInterval(intervalId);
  }, [progress]);

  const progressWidth = { width: `${progress}%` };
  return (
    <div className={`bg-base-100 relative h-2 w-full rounded-md ${margin ? 'mx-4' : ''}`}>
      <div className="bg-base-300 h-2 rounded-md" style={progressWidth} />
    </div>
  );
};

export const LoadingPage = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="text-center">
        <div className="loading loading-spinner loading-lg text-base-300 mb-4"></div>
      </div>
    </div>
  );
};

export default Loading;
