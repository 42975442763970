import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../lib/api';
import PageHeader from '../components/page-header';
import StatusBadge from '../components/status-badge';
import UserContext from '../context/user-context';
import SectionDescription from '../components/section-description';
import Footer from '../components/footer';
import Leaderboard from '../components/leaderboard';
import MainContent, { PaddedContent } from '../components/main-content';
import EventsList from '../components/events-list';

const Series = () => {
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [series, setSeries] = useState({});
  const [leaderboard, setLeaderboard] = useState({});
  const [loadFullLeaderboard, setLoadFullLeaderboard] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(async () => {
    api(user.accessToken)
      .getSeries(params.seriesId)
      .then((response) => {
        response.json().then((body) => {
          setSeries(body.data.event_series);
          setIsLoading(false);
        });
      });
  }, [user.isAuthenticated]);

  useEffect(async () => {
    api(user.accessToken)
      .getSeriesLeaderboard(params.seriesId, loadFullLeaderboard ? 2000 : null)
      .then((response) => {
        response.json().then((body) => {
          setLeaderboard(body.data);
        });
      });
  }, [user.isAuthenticated, loadFullLeaderboard]);

  return (
    <>
      <PageHeader
        title={series.name}
        subTitle={
          <>
            <p className="mb-2 font-normal">{series.description}</p>
            {Boolean(series.website_url) && (
              <a
                className="text-purple-haze-500 hover:text-purple-haze-900 text-sm font-normal underline"
                target="_blank"
                href={series.website_url}
              >
                View Series Website
              </a>
            )}
          </>
        }
      />
      <MainContent>
        <PaddedContent>
          <h2 className="mb-3 font-semibold">Events</h2>
          <SectionDescription>
            Navigate to individual events below to make your picks. Remember to make picks for all
            events to give yourself the best chance of winning the series!
          </SectionDescription>
          <EventsList
            events={series.events}
            isLoading={isLoading}
            includeSeries={false}
            containerClassName="rounded-lg mb-8 border bg-base-300 border-base-100 border-b-0 shadow"
          />
          {leaderboard?.users?.length > 0 && (
            <>
              <h2 className="mb-3 font-semibold">Fantasy Leaderboard</h2>
              <SectionDescription>
                Based on the cumulative scores across all events in the series for which results
                have been posted.
              </SectionDescription>
              {leaderboard.total_user_count > leaderboard.users.length && (
                <p className="mb-3 text-xs">
                  Displaying leaderboard.{' '}
                  <button onClick={() => setLoadFullLeaderboard(true)} className="underline">
                    Show all scores
                  </button>
                  .
                </p>
              )}
              <div className="flex-row sm:flex">
                <Leaderboard
                  users={leaderboard.users}
                  communitySize={leaderboard.total_user_count}
                  showPicksFinalized={false}
                />
              </div>
            </>
          )}
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default Series;
