import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';

const Tooltip = ({ children }) => (
  <div className="tooltip tooltip-primary tooltip-right text-xs font-normal" data-tip={children}>
    <InformationCircleIcon className="ml-1 h-4 w-4 flex-shrink-0 align-top" aria-hidden="true" />
  </div>
);

export default Tooltip;
