import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from '../components/page-header';
import Footer from '../components/footer';
import MainContent, { PaddedContent } from '../components/main-content';

const StatCard = ({ title, imageUrl, description, path }) => (
  <Link className="card bg-base-100 image-full shadow-xl hover:shadow-2xl" to={`/stats/${path}`}>
    <figure>
      <img src={imageUrl} alt={title} />
    </figure>
    <div className="card-body">
      <h2 className="card-title">{title}</h2>
      <p className="text-sm">{description}</p>
      <div className="card-actions mt-4 justify-end">
        <button className="btn btn-primary">View {title}</button>
      </div>
    </div>
  </Link>
);

const Stats = () => {
  return (
    <>
      <PageHeader title="Stats" subTitle="View an assortment of trail running stats" />
      <MainContent>
        <PaddedContent>
          <div className="mt-2 grid grid-cols-1 sm:grid-cols-3">
            <StatCard
              title="Splits"
              description="View historical time splits for trail races. Filter by race, year, and athlete."
              imageUrl="https://d2gwq01zobnbgs.cloudfront.net/assets/content/stats/race-splits.png"
              path="splits"
            />
          </div>
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default Stats;
