import React from 'react';
import Footer from '../components/footer';

const Terms = () => (
  <>
    <h1 className="mb-8 mt-16 text-center text-2xl font-bold">Terms of Service</h1>
    <div className="prose prose-sm dark:prose-invert bg-base-300 mx-auto mb-16 max-w-3xl p-8">
      <p className="lead">
        These Terms of Service govern your use of fantasy.freetrail.com. By accessing or using our
        services, you agree to be bound by these terms. These terms may be modified at any time,
        without notice, at the sole discretion of Freetrail, LLC. Continued use of the service
        constitutes acceptance of any changes.
      </p>

      <h2>1. Account Terms</h2>
      <ul>
        <li>You must provide accurate and complete information when creating an account.</li>
        <li>You are responsible for maintaining the security of your account and password.</li>
        <li>You are responsible for all activity that occurs under your account.</li>
      </ul>

      <h2>2. Acceptable Use</h2>
      <ul>
        <li>You agree not to use the service for any illegal or unauthorized purpose.</li>
        <li>You must not violate any laws in your jurisdiction while using our service.</li>
        <li>
          You must not post or transmit malicious code or attempt to interfere with the service's
          functionality.
        </li>
        <li>You must not harass, abuse, or harm other users of the service.</li>
        <li>You must not impersonate any person or entity in using the service.</li>
      </ul>

      <h2>3. Termination</h2>
      <ul>
        <li>We reserve the right to suspend or terminate your account at any time.</li>
        <li>Upon termination, your right to use the service will immediately cease.</li>
        <li>
          All provisions of these Terms which by their nature should survive termination shall
          survive termination.
        </li>
      </ul>

      <h2>4. Disclaimer of Warranties</h2>
      <p>
        The service is provided "as is" without warranty of any kind, either express or implied.
        Freetrail, LLC does not warrant that the service will be uninterrupted or error-free.
      </p>
    </div>
    <Footer />
  </>
);

export default Terms;
