import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Nav from './components/nav';
import Dashboard from './routes/dashboard';
import Event from './routes/event';
import Events from './routes/events';
import api from './lib/api';
import { UserProvider } from './context/user-context';
import Privacy from './routes/privacy';
import { postAuthRedirect } from './lib/auth';
import FAQ from './routes/faq';
import usePageTracking from './lib/analytics';
import { getUserName } from './lib/model';
import Series from './routes/series';
import Athletes from './routes/athletes';
import Content, { ContentKey } from './routes/content';
import NotFound from './routes/not-found';
import Leaderboards from './routes/leaderboards';
import FreeAgency from './routes/free-agency';
import Settings from './routes/settings';
import ScrollToTop from './components/scroll-to-top';
import LeagueRoute from './routes/league';
import LeagueInviteRoute from './routes/league-invite';
import HeadToHead from './routes/head-to-head';
import TROY from './routes/troy';
import Stats from './routes/stats';
import Splits from './routes/stats/splits';
import Teams from './routes/teams';
import Team from './routes/team';
import Terms from './routes/terms';
import Home from './routes/home';
import { ThemeProvider } from './context/theme-context';

const AUTH0_AUDIENCE = 'https://fantasy.freetrail.com';

const App = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [contextUser, setContextUser] = useState({ isAuthenticated: false, isLoading: isLoading });
  const [isdark, setIsdark] = useState(JSON.parse(localStorage.getItem('isDarkMode') || 'false'));
  const navigate = useNavigate();

  usePageTracking();

  const handleThemeChange = () => {
    setIsdark(!isdark);
    localStorage.setItem('isDarkMode', JSON.stringify(!isdark));
  };

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        getAccessTokenSilently({
          audience: AUTH0_AUDIENCE
        }).then((token) => {
          api(token)
            .createUser({
              name: getUserName(user),
              auth0_user_id: user.sub,
              profile_image_url: user.picture
            })
            .then((response) => {
              response.json().then((body) => {
                setContextUser({
                  isAuthenticated: true,
                  isLoading: false,
                  accessToken: token,
                  displayName: body.data.display_name,
                  ...user
                });
              });
            });
        });
      } else {
        setContextUser({ isAuthenticated: false, isLoading: false });
      }
    }

    if (!isLoading && !isAuthenticated && ['/settings'].includes(window.location.pathname)) {
      navigate('/');
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading || (isAuthenticated && !contextUser.accessToken)) {
    return null;
  }

  if (isAuthenticated) {
    postAuthRedirect();
  }

  return (
    <UserProvider value={contextUser}>
      <ThemeProvider value={{ isDarkMode: isdark }}>
        <div data-theme={isdark ? 'freetrail-dark' : 'freetrail-light'}>
          <Nav onThemeChange={handleThemeChange} isDarkMode={isdark} />
          <Routes>
            <Route path="*" element={<NotFound />} />
            {isAuthenticated ? (
              <Route path="/" element={<Dashboard />} />
            ) : (
              <Route path="/" element={<Home />} />
            )}
            <Route path="privacy" element={<Privacy />} />
            <Route path="terms" element={<Terms />} />
            <Route path="faqs" element={<FAQ />} />
            <Route path="leaderboards" element={<Leaderboards />} />
            <Route path="athletes" element={<Athletes />} />
            <Route path="athletes/:athleteProfileSlug" element={<Athletes />} />
            <Route path="athletes/free-agency/:year" element={<FreeAgency />} />
            <Route path="athletes/head-to-head" element={<HeadToHead />} />
            <Route path="athletes/teams" element={<Teams />} />
            <Route path="teams/:teamProfileSlug" element={<Team />} />
            <Route path="events" element={<Events />} />
            <Route path="events/:eventId" element={<Event />} />
            <Route path="series/:seriesId" element={<Series />} />
            <Route path="leagues/:leagueId" element={<LeagueRoute />} />
            <Route path="leagues/join/:inviteCode" element={<LeagueInviteRoute />} />
            <Route path="stats" element={<Stats />} />
            <Route path="stats/splits" element={<Splits />} />
            <Route path="settings" element={<Settings />} />
            <Route path="troy/:year" element={<TROY />} />
            <Route path="utmb-2022" element={<Content contentKey={ContentKey.UtmbPrizes} />} />
            <Route
              path="utmb-2022-preview"
              element={<Content contentKey={ContentKey.UtmbPreview} />}
            />
          </Routes>
        </div>
      </ThemeProvider>
    </UserProvider>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <BrowserRouter>
      <ScrollToTop />
      <Auth0Provider
        domain="mut-guru.us.auth0.com"
        clientId="aOBTGMZ66aoiOHXeYjH3aPjlVE81yuyO"
        redirectUri={window.location.origin}
        audience={AUTH0_AUDIENCE}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
    </BrowserRouter>,
    document.getElementById('app')
  );
});
