import React from 'react';
import { Link } from 'react-router-dom';

const SeriesBadges = ({ event }) => {
  return (
    <>
      {event.event_series.map((series) => (
        <Link
          className="bg-base-300 hover:bg-base-200 text-base-content inline-flex items-center rounded-full px-3 py-1 text-sm font-medium leading-5 transition-colors duration-150 ease-in-out"
          to={`/series/${series.uuid}`}
          key={series.id}
          onClick={(e) => e.stopPropagation()}
        >
          {series.name}
        </Link>
      ))}
    </>
  );
};

export default SeriesBadges;
