import React, { PropsWithChildren } from 'react';
import Select from 'react-select';

export const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#eef766' : '',
    color: state.isSelected ? 'black' : ''
  })
};

export const selectClassNames = {
  control: () => 'select select-bordered min-h-[2.5rem] h-auto p-2 pl-3',
  indicatorsContainer: () => '!hidden',
  menuList: () => 'rounded-lg border border-base-100',
  option: () => 'p-2 bg-base-200 border-b hover:bg-base-300 last:border-b-0 border-base-100',
  multiValue: () => 'bg-base-300 rounded-md px-2 text-xs mr-2 mb-2',
  noOptionsMessage: () => 'p-2 bg-base-200'
};

interface SeasonSelectProps {
  onChange: ({ value }: { value: string }) => void;
  year?: number;
  maxYear?: number;
  yearOptions?: number[];
}

const SeasonSelect = ({
  onChange,
  year,
  maxYear,
  yearOptions
}: PropsWithChildren<SeasonSelectProps>) => {
  const currentYear = new Date().getFullYear();

  if (!yearOptions) {
    yearOptions = [2022];
    while (yearOptions[0] < (maxYear || currentYear)) {
      yearOptions.unshift(yearOptions[0] + 1);
    }
  }

  const defaultYear = year || yearOptions[0];

  return (
    <>
      <label className="mb-2 block font-medium">Season</label>
      <Select
        className="
          mb-6
          block
          w-48
          text-sm
          font-normal"
        defaultValue={{ value: defaultYear, label: defaultYear }}
        options={yearOptions.map((year) => ({ value: year, label: year }))}
        onChange={onChange}
        styles={selectStyles}
        classNames={selectClassNames}
        unstyled
      />
    </>
  );
};

export default SeasonSelect;
