import React from 'react';
import { format, parseISO } from 'date-fns';
import RankText from './rank-text';
import UserAvatar from './user-avatar';
import Loading from './loading';
import DataTable from 'react-data-table-component';

interface LeaderboardUser {
  is_current_user: boolean;
  rank: number;
  name: string;
  profile_image_url: string;
  total_score: number;
  female_score: number;
  male_score: number;
  picks_updated_at: string;
}

const Leaderboard = ({
  users,
  communitySize,
  showPicksFinalized = true
}: {
  users: LeaderboardUser[];
  communitySize: number;
  showPicksFinalized?: boolean;
}) => {
  return (
    <DataTable
      className="leaderboard-table base-table data-table rounded-table mb-8 mt-2"
      fixedHeader
      columns={[
        {
          name: `Rank / ${communitySize.toLocaleString()}`,
          selector: (row) => row.rank,
          cell: (row) => (
            <span className="text-sm">
              <RankText rank={row.rank} />
            </span>
          ),
          minWidth: '110px'
        },
        {
          name: 'Name',
          grow: 2,
          cell: (row) => (
            <>
              <UserAvatar avatarURL={row.profile_image_url} />
              <span className="mr-1">{row.name}</span>
            </>
          ),
          minWidth: '175px'
        },
        {
          name: 'Total Score',
          grow: 2,
          selector: (row) => row.total_score,
          format: (row) => row.total_score.toLocaleString(),
          sortable: true,
          minWidth: '150px'
        },
        {
          name: 'Women’s Score',
          grow: 2,
          selector: (row) => row.female_score,
          format: (row) => row.female_score.toLocaleString(),
          sortable: true,
          minWidth: '150px'
        },
        {
          name: 'Men’s Score',
          grow: 2,
          selector: (row) => row.male_score,
          format: (row) => row.male_score.toLocaleString(),
          sortable: true,
          minWidth: '150px'
        },
        {
          name: 'Picks Finalized',
          selector: (row) => row.picks_updated_at,
          format: (row) => format(parseISO(row.picks_updated_at), 'LLL d, yyyy'),
          sortable: true,
          omit: !showPicksFinalized,
          minWidth: '150px'
        }
      ]}
      data={users}
      defaultSortFieldId={2}
      defaultSortAsc={false}
      progressPending={communitySize === 0}
      progressComponent={<Loading margin />}
      noDataComponent={<span className="my-12 px-4 text-sm">...</span>}
      conditionalRowStyles={[
        {
          when: (row) => row.is_current_user,
          classNames: ['active-row']
        }
      ]}
    />
  );
};

export default Leaderboard;
