import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Footer from '../components/footer';
import Loading, { LoadingPage } from '../components/loading';
import PageHeader from '../components/page-header';
import UserContext from '../context/user-context';
import api from '../lib/api';
import Select, { SingleValue } from 'react-select';
import MainContent, { PaddedContent } from '../components/main-content';
import { EventData, EventStatus, League, LeagueSeason } from '../lib/model';
import UserAvatar from '../components/user-avatar';
import LeagueSettingsForm from '../components/league-settings-form';
import { STATUS_TEXT } from '../components/status-badge';
import { formatDatetime } from '../lib/strings';
import DataTable from 'react-data-table-component';
import { SeasonLeaderboard } from './leaderboards';
import SeasonSelect, { selectClassNames, selectStyles } from '../components/season-select';

function formatEventName(event: EventData): string {
  return `${event.name} ${event.format ? `(${event.format})` : ''}`;
}

const CopyInviteLink: React.FC<{ inviteCode: string }> = ({ inviteCode }) => {
  const [copied, setCopied] = useState(false);
  const inviteLink = `${window.location.origin}/leagues/join/${inviteCode}`;

  return (
    <label className="input input-bordered flex w-full max-w-lg items-center gap-2 text-sm">
      <input type="text" readOnly value={inviteLink} className="grow" placeholder="Search" />
      <button
        className="btn btn-ghost btn-sm"
        onClick={() => {
          navigator.clipboard.writeText(inviteLink);
          setCopied(true);
        }}
      >
        {copied ? 'Copied!' : 'Copy'}
      </button>
    </label>
  );
};

const LeagueRoute: React.FC = () => {
  const params = useParams() as { leagueId: string };
  const [searchParams, _] = useSearchParams();
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingEvents, setIsSavingEvents] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const [displaySettings, setDisplaySettings] = useState(Boolean(searchParams.get('settings')));
  const [showSaved, setShowSaved] = useState(false);
  const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);
  const [league, setLeague] = useState<League | undefined>(undefined);
  const [season, setSeason] = useState<LeagueSeason | undefined>(undefined);
  const [events, setEvents] = useState<EventData[]>([]);
  const [leaderboardEvent, setLeaderboardEvent] = useState<
    SingleValue<{ value: string; label: string }>
  >({
    value: '',
    label: 'All League Events'
  });
  const [leagueEvents, setLeagueEvents] = useState<Readonly<{ value: string; label: string }[]>>(
    []
  );

  const fetchEvents = async (year: number) => {
    const eventsData = await api(user.accessToken).getEvents(year);
    setEvents(eventsData);
  };

  useEffect(() => {
    if (!user.accessToken) {
      return;
    }

    const fetchLeague = async () => {
      const leagueData = await api(user.accessToken).getLeague(params.leagueId);
      setIsLoading(false);
      setLeague(leagueData);
      setSeason(leagueData.current_season);
      setLeagueEvents(
        leagueData.current_season.events.map((e) => ({
          value: e.id.toString(),
          label: formatEventName(e)
        }))
      );
      fetchEvents(leagueData.current_season.year);
    };

    fetchLeague();
  }, [user.isAuthenticated]);

  const saveLeagueEvents = useCallback(async () => {
    setIsSavingEvents(true);
    await api(user.accessToken).selectLeagueEvents(
      league!.uuid,
      season!.id,
      leagueEvents.map((e) => ({
        event_id: parseInt(e.value)
      }))
    );
    setIsSavingEvents(false);
    setShowSaved(true);
    setTimeout(() => setShowSaved(false), 2000);
  }, [league, leagueEvents, season]);

  const saveLeagueSettings = useCallback(
    async ({ name, avatarUrl }: { name: string; avatarUrl: string }, after: () => void) => {
      setIsSavingSettings(true);
      await api(user.accessToken).updateLeague(league!.uuid, { name, avatar_url: avatarUrl });
      after();
      setIsSavingSettings(false);
    },
    [league, user]
  );

  const handleSelectSeason = (year: number) => {
    const season = league!.league_seasons.find((s) => s.year === year);
    setSeason(season);
    setLeagueEvents(
      season!.events.map((e) => ({
        value: e.id.toString(),
        label: formatEventName(e)
      }))
    );
    fetchEvents(year);
  };

  const handleLeaveLeague = async () => {
    await api(user.accessToken).leaveLeague(league!.uuid);
    window.location.href = '/';
  };

  if (league === undefined || season === undefined) {
    return <LoadingPage />;
  }

  return (
    <>
      <PageHeader
        title={
          <>
            <img className="mr-3 inline h-10 w-10 rounded" src={league.avatar_url} />
            <span className="text-xl sm:text-2xl">{league.name}</span>
          </>
        }
        subTitle="Fantasy League"
      />
      <MainContent>
        <PaddedContent>
          <SeasonSelect
            onChange={({ value }) => handleSelectSeason(parseInt(value))}
            yearOptions={league.league_seasons.map((s) => s.year).reverse()}
            year={season.year}
          />
          {league.current_user_admin && (
            <>
              <button
                onClick={() => setDisplaySettings(!displaySettings)}
                className="btn btn-primary btn-sm mb-8"
              >
                {displaySettings ? 'Hide' : 'Show'} admin settings
              </button>
              {displaySettings && (
                <>
                  <h3 className="mb-4 text-base font-medium">
                    Manage League <span className="ml-1 text-xs">(admin)</span>
                  </h3>
                  <div className="grid grid-cols-1 gap-x-8 text-sm sm:grid-cols-2">
                    <div className="mb-8">
                      <LeagueSettingsForm
                        isSaving={isSavingSettings}
                        league={league}
                        onSubmit={saveLeagueSettings}
                      />
                      <h4 className="mb-4 mt-8 text-sm font-medium">Add Members</h4>
                      <p className="mb-4">
                        Share this link with friends to invite them to your league.
                      </p>
                      <CopyInviteLink inviteCode={league!.invite_code!} />
                    </div>
                    <div className="mb-8">
                      <h4 className="mb-4 text-sm font-medium">Select {season.year} Events</h4>
                      <p className="mb-4 leading-relaxed">
                        Choose the events you want to include in your league. You can change this at
                        any time, and the leaderboard will update automatically. Events will be
                        added to the platform throughout the year.
                      </p>
                      <Select
                        className="
                                m-0
                                mt-1
                                block
                                w-full
                                max-w-lg
                                text-sm
                                font-normal"
                        closeMenuOnSelect={false}
                        formatOptionLabel={({ value, label }) => {
                          const event = events.find((event) => event.id === parseInt(value));

                          return (
                            <div>
                              {event && (
                                <img
                                  src={event.image_url}
                                  className="mr-2 inline h-5 w-5 rounded-full"
                                />
                              )}
                              <span>{label}</span>
                            </div>
                          );
                        }}
                        options={events.map((event) => ({
                          value: event.id.toString(),
                          label: formatEventName(event)
                        }))}
                        isMulti
                        onChange={(selected) => {
                          setLeagueEvents(selected);
                        }}
                        value={leagueEvents}
                        placeholder="Select events..."
                        styles={selectStyles}
                        classNames={selectClassNames}
                        unstyled
                      />
                      <button className="btn btn-primary mt-4" onClick={saveLeagueEvents}>
                        {isSavingEvents ? 'Saving...' : 'Save Events'}
                      </button>
                      {showSaved && (
                        <span className="badge badge-primary ml-4 text-xs">Saved!</span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {showLeaveConfirm && (
            <dialog className="modal modal-open">
              <div className="modal-box">
                <h3 className="text-lg font-bold">Leave League</h3>
                <p className="py-4">Are you sure you want to leave this league?</p>
                <div className="modal-action">
                  <button className="btn" onClick={() => setShowLeaveConfirm(false)}>
                    Cancel
                  </button>
                  <button className="btn btn-neutral" onClick={handleLeaveLeague}>
                    Leave League
                  </button>
                </div>
              </div>
              <form method="dialog" className="modal-backdrop">
                <button onClick={() => setShowLeaveConfirm(false)}>close</button>
              </form>
            </dialog>
          )}
          <div className="grid grid-cols-2 gap-x-8 text-sm">
            <div className="col-span-2 mb-12">
              <h3 className="mb-4 text-base font-medium">Leaderboard</h3>
              <Select
                className="
                                m-0
                                mb-8
                                block
                                max-w-sm
                                text-sm"
                formatOptionLabel={({ value, label }) => {
                  const event = season!.events.find((event) => event.uuid === value);

                  return (
                    <div>
                      {event && (
                        <img src={event.image_url} className="mr-2 inline h-5 w-5 rounded-full" />
                      )}
                      <span>{label}</span>
                    </div>
                  );
                }}
                options={[
                  {
                    value: '',
                    label: 'All League Events'
                  },
                  ...season!.events
                    .filter((e) => e.status === EventStatus.ResultsPosted)
                    .map((event) => ({
                      value: event.uuid.toString(),
                      label: formatEventName(event)
                    }))
                ]}
                onChange={(selected) => {
                  setLeaderboardEvent(selected);
                }}
                value={leaderboardEvent}
                placeholder="Select event"
                styles={selectStyles}
                classNames={selectClassNames}
                unstyled
              />
              <SeasonLeaderboard
                eventId={leaderboardEvent?.value ? leaderboardEvent.value : undefined}
                leagueId={league.uuid}
                noDataComponent={
                  <span className="text-base-content my-20">
                    Your leaderboard will populate when the first league event has been scored.
                  </span>
                }
                year={season!.year}
                tableClassName="rounded-table"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 sm:grid-cols-2">
            <div>
              <h3 className="text-base font-medium">Events</h3>
              <DataTable
                className="data-table base-table rounded-table my-4"
                columns={[
                  {
                    name: 'Name',
                    sortable: true,
                    selector: (row) => row.name,
                    grow: 3,
                    cell: (row) => (
                      <>
                        <img
                          src={row.image_url}
                          className="mr-3 inline h-8 w-8 rounded-full"
                          referrerPolicy="no-referrer"
                        />
                        <span>
                          <Link className="" to={`/events/${row.uuid}?league=${league.uuid}`}>
                            {formatEventName(row)}
                          </Link>
                        </span>
                      </>
                    )
                  },
                  {
                    name: 'Date',
                    selector: (row) => row.closes_at,
                    format: (row) => (row.closes_at ? formatDatetime(row.closes_at) : '-'),
                    sortable: true,
                    minWidth: '120px'
                  },
                  {
                    name: 'Status',
                    selector: (row) => row.status,
                    cell: (row) => STATUS_TEXT[row.status],
                    sortable: true,
                    hide: 'md'
                  }
                ]}
                customStyles={{
                  rows: {
                    style: {
                      minHeight: 60,
                      paddingLeft: '15px'
                    }
                  },
                  cells: {
                    style: {
                      paddingLeft: '0'
                    }
                  }
                }}
                data={season!.events}
                defaultSortFieldId={2}
                defaultSortAsc={false}
                responsive
                noDataComponent={
                  <span className="text-base-content my-8 text-sm">
                    No events selected for {season.year}.
                  </span>
                }
                pagination
                paginationPerPage={10}
                paginationComponentOptions={{ noRowsPerPage: true }}
                progressPending={isLoading}
                progressComponent={<Loading />}
              />
            </div>
            <div>
              <h3 className="text-base font-medium">Members</h3>
              <DataTable
                className="data-table base-table rounded-table my-4"
                columns={[
                  {
                    name: 'Name',
                    sortable: true,
                    selector: (row) => row.user.display_name,
                    grow: 3,
                    cell: (row) => (
                      <>
                        <UserAvatar avatarURL={row.user.profile_image_url} />
                        <span>{row.user.display_name}</span>
                        {row.admin && <span className="ml-2 text-xs text-gray-500">(admin)</span>}
                      </>
                    )
                  },
                  {
                    name: 'Joined',
                    selector: (row) => row.created_at,
                    format: (row) => formatDatetime(row.created_at),
                    sortable: true,
                    minWidth: '120px'
                  }
                ]}
                customStyles={{
                  rows: {
                    style: {
                      minHeight: 60,
                      paddingLeft: '15px'
                    }
                  },
                  cells: {
                    style: {
                      paddingLeft: '0'
                    }
                  }
                }}
                data={league.league_users}
                defaultSortFieldId={2}
                defaultSortAsc={true}
                responsive
                pagination
                paginationPerPage={10}
                paginationComponentOptions={{ noRowsPerPage: true }}
                progressPending={isLoading}
                progressComponent={<Loading />}
              />
            </div>
          </div>
          {!league.current_user_admin && (
            <button
              onClick={() => setShowLeaveConfirm(true)}
              className="btn btn-neutral btn-sm mb-8"
            >
              Leave League
            </button>
          )}
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default LeagueRoute;
