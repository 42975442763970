import React, { PropsWithChildren } from 'react';

interface FlashMessageProps {
  type: 'notice' | 'info' | 'announcement';
  small?: boolean;
}

const FlashMessage = ({ type, small = false, children }: PropsWithChildren<FlashMessageProps>) => {
  let alertClass = '';

  switch (type) {
    case 'notice':
      alertClass =
        'alert-warning dark:bg-amber-900 dark:text-amber-100 bg-amber-100 text-amber-800';
      break;
    case 'info':
      alertClass = 'alert-info dark:bg-sky-900 dark:text-sky-100 bg-sky-100 text-sky-800 ';
      break;
    case 'announcement':
      alertClass = 'dark:bg-secondary dark:text-base-content bg-purple-haze-100 text-secondary ';
      break;
  }

  return (
    <div
      className={`alert border-transparent px-2 sm:px-4 ${alertClass} my-5 mt-1 ${
        small ? 'text-xs' : 'text-sm'
      }`}
    >
      {children}
    </div>
  );
};

export default FlashMessage;
