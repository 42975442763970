import React from 'react';
import CloseModalButton from './close-modal-button';
import FlashMessage from './flash-message';
import EventCountdown from './event-countdown';

interface PostSubmissionModalProps {
  onClose: () => void;
  isVoteContest: boolean;
  maxPicksPerField: number;
  picksCount: number;
  eventClosesAt: string;
}

const PostSubmissionModal = ({
  onClose,
  isVoteContest,
  maxPicksPerField,
  picksCount,
  eventClosesAt
}: PostSubmissionModalProps) => {
  return (
    <div className="modal modal-open">
      <div className="modal-box bg-base-200 relative flex max-w-md flex-col space-y-4">
        <div>
          <h3 className="text-base font-semibold">
            Thanks for {isVoteContest ? 'voting' : 'playing'}!
          </h3>
        </div>
        <div className="flex flex-col space-y-6">
          <p className="mb-0 text-sm leading-relaxed">
            Your picks have been saved! You can update your selections as many times as you like
            until{' '}
            {isVoteContest
              ? 'the voting period concludes'
              : 'the event is locked—generally just before the race starts'}
            .
          </p>
          <p className="mt-2 text-sm leading-relaxed">
            <EventCountdown closesAt={eventClosesAt} />
          </p>
          {!isVoteContest && picksCount < maxPicksPerField * 2 && (
            <FlashMessage type="notice">
              <span className="text-xs">
                Make {maxPicksPerField} picks per field to maximize your score!
              </span>
            </FlashMessage>
          )}
        </div>
        <div className="modal-action">
          <button className="btn btn-ghost" onClick={onClose}>
            Back to Event
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostSubmissionModal;
