import React, { useCallback } from 'react';
import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import UserContext from '../context/user-context';
import PageHeader from '../components/page-header';
import api from '../lib/api';
import Footer from '../components/footer';
import DataTable from 'react-data-table-component';
import { EventStatus } from '../lib/model';
import RankText from '../components/rank-text';
import Loading from '../components/loading';
import { formatDatetime } from '../lib/strings';
import MainContent, { PaddedContent } from '../components/main-content';
import CreateLeagueModal from '../components/create-league-modal';

const USER_ACTIVITY_COUNT = 10;

const ActivityList = ({ heading, isLoading, noPicksContent, picks }) => {
  const [historyLoaded, setHistoryLoaded] = useState(false);
  const data = historyLoaded ? picks : picks.slice(0, USER_ACTIVITY_COUNT);

  return (
    <div>
      <h2 className="mb-3 font-medium">{heading}</h2>
      <DataTable
        className="data-table activity-table"
        columns={[
          {
            name: 'EVENT',
            sortable: true,
            selector: (row) => row.event.name_and_format,
            grow: 3,
            cell: (row) => (
              <>
                <img
                  src={row.event.image_url}
                  className="mr-3 inline h-8 w-8 rounded-full"
                  referrerPolicy="no-referrer"
                />
                <span>
                  <Link className="" to={`/events/${row.event.uuid}`}>
                    {row.event.name_and_format}
                  </Link>
                </span>
              </>
            )
          },
          {
            name: 'DATE',
            selector: (row) => row.picks_updated_at || row.created_at,
            format: (row) => formatDatetime(row.picks_updated_at || row.created_at),
            sortable: true,
            minWidth: '120px'
          },
          {
            name: 'RANK',
            selector: (row) => row.rank,
            cell: (row) => (
              <RankText
                rank={row.event.status === EventStatus.ResultsPosted ? row.rank : undefined}
              />
            ),
            sortable: true,
            hide: 'md'
          }
        ]}
        customStyles={{
          rows: {
            style: {
              minHeight: 60
            }
          }
        }}
        data={data}
        defaultSortFieldId={2}
        defaultSortAsc={false}
        responsive
        noDataComponent={noPicksContent}
        progressPending={isLoading}
        progressComponent={<Loading />}
      />
      {picks.length > USER_ACTIVITY_COUNT && (
        <button
          className="hyperlink my-6 text-xs underline"
          onClick={() => setHistoryLoaded(!historyLoaded)}
        >
          {historyLoaded ? 'Hide ' : 'Show '}extended activity history
        </button>
      )}
    </div>
  );
};

const CreateLeagueRow = ({ onClick }) => (
  <button className="btn btn-sm btn-primary mt-6" onClick={onClick}>
    Create League
  </button>
);

const Dashboard = () => {
  const user = useContext(UserContext);
  const [showCreateLeagueModal, setShowCreateLeagueModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboard, setDashboard] = useState({
    user_picks: [],
    other_picks: [],
    league_users: []
  });

  useEffect(async () => {
    if (!user.accessToken) {
      return;
    }

    api(user.accessToken)
      .getUserDashboard()
      .then((response) => {
        response.json().then((body) => {
          setIsLoading(false);
          setDashboard(body.data);
        });
      });
  }, [user.isAuthenticated]);

  return (
    <>
      <PageHeader title="Dashboard" subTitle="View your activity and stats" />
      <MainContent>
        <PaddedContent>
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <div className="mb-4 sm:mr-10">
              <ActivityList
                heading="Latest Activity"
                isLoading={isLoading}
                picks={dashboard.user_picks}
                noPicksContent={
                  <>
                    <div className="text-base-content border-base-300 relative mb-3 flex border-b p-6 pl-0 text-sm">
                      <img src={user.picture} className="mr-3 inline-block h-8 w-8 rounded-full" />
                      <div>
                        Hey there! Head on over to the{' '}
                        <Link className="hyperlink underline" to={`/events`}>
                          Events page
                        </Link>{' '}
                        to find an upcoming race and make your first picks!
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            <div>
              <h2 className="mb-3 font-medium">Global Stats</h2>
              <DataTable
                className="data-table activity-table mb-8"
                keyField="year"
                columns={[
                  {
                    name: 'YEAR',
                    selector: (row) => row.year,
                    sortable: true
                  },
                  {
                    name: 'RANK',
                    selector: (row) => row.rank,
                    cell: (row) => <RankText rank={row.rank} />,
                    sortable: true
                  },
                  {
                    name: 'TOTAL SCORE',
                    selector: (row) => row.total_score,
                    format: (row) => row.total_score.toLocaleString(),
                    sortable: true,
                    grow: 2
                  },
                  {
                    name: '# of EVENTS',
                    selector: (row) => row.events_played,
                    format: (row) => row.events_played.toLocaleString(),
                    sortable: true,
                    grow: 2,
                    hide: 'md'
                  },
                  {
                    name: 'AVG RANK',
                    selector: (row) => row.average_rank.toLocaleString(),
                    sortable: true,
                    grow: 2,
                    hide: 'md'
                  }
                ]}
                customStyles={{
                  rows: {
                    style: {
                      minHeight: 60
                    }
                  }
                }}
                data={dashboard.stats}
                defaultSortFieldId={1}
                defaultSortAsc={false}
                responsive
                noDataComponent={
                  <p className="text-base-content w-full text-sm">
                    Your seasonal statistics will appear here once you’ve participated in your first
                    event.
                  </p>
                }
                progressPending={isLoading}
                progressComponent={<Loading />}
              />
              <h2 className="mb-4 font-medium">Leagues</h2>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  {showCreateLeagueModal && (
                    <CreateLeagueModal onClose={() => setShowCreateLeagueModal(false)} />
                  )}
                  <div className="mb-8">
                    {dashboard.league_users.length > 0 ? (
                      <>
                        {dashboard.league_users.map((leagueUser, i) => (
                          <Link
                            className="group mt-6 block text-sm"
                            to={`/leagues/${leagueUser.league.uuid}`}
                            key={`league-user-${i}`}
                          >
                            <img
                              className="mr-3 inline-block h-8 w-8 rounded group-hover:opacity-75"
                              src={leagueUser.league.avatar_url}
                            />
                            <h3 className="inline-block font-normal">{leagueUser.league.name}</h3>
                          </Link>
                        ))}
                        <CreateLeagueRow onClick={() => setShowCreateLeagueModal(true)} />
                      </>
                    ) : (
                      <div>
                        <p className="text-sm leading-relaxed">
                          You are not a member of any leagues. Leagues allow you to compete against
                          others on a private leaderboard for events of your choosing.{' '}
                        </p>
                        <CreateLeagueRow onClick={() => setShowCreateLeagueModal(true)} />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default Dashboard;
