import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="footer dark:bg-base-200 text-primary-content dark:text-base-content bg-zinc-900 p-10">
    <aside>
      <a href="https://freetrail.com">
        <img src="/images/ft-logo.svg" className="invert w-28" />
      </a>
      <p>© {new Date().getFullYear()} Freetrail</p>
    </aside>
    <nav>
      <h6 className="footer-title">Links</h6>
      <div className="grid grid-flow-col gap-4">
        <Link to="/faqs" className="">
          FAQs
        </Link>
        <Link to="/terms" className="">
          Terms of Service
        </Link>
        <Link to="/privacy" className="">
          Privacy Policy
        </Link>
      </div>
    </nav>
  </footer>
);

export default Footer;
