import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../lib/api';
import UserContext from '../context/user-context';
import PageHeader from '../components/page-header';
import MainContent, { PaddedContent } from '../components/main-content';
import Loading from '../components/loading';
import { AthletesSubNav } from '../components/nav';
import { Team } from '../lib/model';
import SectionDescription from '../components/section-description';

const Teams: React.FC = () => {
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [teams, setTeams] = useState<Team[]>([]);

  useEffect(() => {
    const fetchTeams = async () => {
      setIsLoading(true);
      try {
        const teams = await api(user.accessToken).getTeams();
        setTeams(teams);
      } catch (error) {
        console.error('Error fetching teams:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeams();
  }, [user.accessToken]);

  return (
    <>
      <AthletesSubNav />
      <PageHeader title="Teams" subTitle="Select a team to view its trail running roster" />
      <MainContent>
        <PaddedContent>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="mb-4">
                <SectionDescription>
                  Team rosters are updated on a best-effort basis. Please contact us if you have a
                  correction.
                </SectionDescription>
              </div>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
                {teams.map((team) => (
                  <Link
                    key={team.profile_slug}
                    to={`/teams/${team.profile_slug}`}
                    className="card card-side bg-accent/20 hover:bg-accent/30 shadow-md"
                  >
                    <figure className="p-4">
                      <img
                        src={team.logo_urls.standard}
                        alt={`${team.name} logo`}
                        className="h-16 w-16 rounded-full object-cover"
                      />
                    </figure>
                    <div className="card-body py-4">
                      <h3 className="card-title text-base">{team.name}</h3>
                      <div className="card-actions">
                        <span className="hyperlink text-sm font-medium">View Team</span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </PaddedContent>
      </MainContent>
    </>
  );
};

export default Teams;
