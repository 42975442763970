import React from 'react';
import Flags from 'country-flag-icons/react/3x2';

interface NationalityFlagProps {
  country: string | undefined;
  showCountryName?: boolean;
}

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const NationalityFlag = ({ country, showCountryName }: NationalityFlagProps) => {
  const Flag = Flags[country || ''];

  return (
    (Flag && country !== undefined && (
      <span>
        <div className="inline-block h-4 w-5 shadow-lg">
          <Flag className="inline-block h-full w-full flex-shrink-0 rounded-[4px]" />
        </div>
        {showCountryName && <span className="ml-2">{regionNames.of(country)}</span>}
      </span>
    )) ||
    null
  );
};

export default NationalityFlag;
