import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import api, { pickAttributes } from '../lib/api';
import PageHeader from '../components/page-header';
import UserContext from '../context/user-context';
import Footer from '../components/footer';
import MainContent, { PaddedContent } from '../components/main-content';
import { StravaIcon } from '../components/icons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../components/loading';
import { UserProfile } from '../lib/model';
import AthleteSelect from '../components/athlete-select';
import FlashMessage from '../components/flash-message';
import Form from '../components/form';

const ConnectWithStravaButton = ({ onClick }) => (
  <button onClick={onClick} className="-ml-[4px]">
    <img src="https://d2gwq01zobnbgs.cloudfront.net/assets/strava/btn_strava_connectwith_orange.svg" />
  </button>
);

const Settings = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [stravaLoading, setStravaLoading] = useState(
    Boolean(searchParams.get('code') && searchParams.get('scope'))
  );
  const [stravaConnected, setStravaConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isSavingAthlete, setIsSavingAthlete] = useState(false);
  const [showAthleteSaved, setShowAthleteSaved] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(undefined);
  const [selectedAthleteId, setSelectedAthleteId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!user.isAuthenticated) return;

    const getUserProfile = async () => {
      const data = await api(user.accessToken).getCurrentUserProfile();

      // If the user hasn't set an email for notifications, default to their auth account email if it exists
      if (!Boolean(data['email']) && Boolean(user.email)) {
        data['email'] = user.email;
      }

      setUserProfile(data);
      setIsLoading(false);
      setStravaConnected(Boolean(data.strava_account));
    };

    getUserProfile();
  }, [user.isAuthenticated]);

  useEffect(() => {
    const connectStrava = async () => {
      try {
        const data = await api(user.accessToken).createStravaAccount(
          searchParams.get('code')!!,
          searchParams.get('scope')!!
        );
        setStravaConnected(true);
      } catch (e) {
      } finally {
        setStravaLoading(false);
      }
    };

    if (user.isAuthenticated && stravaLoading) {
      connectStrava();
    } else {
      setStravaLoading(false);
    }
  }, [user.isAuthenticated, searchParams]);

  const handleSubmit = (attrs: string[]) => {
    const data = pickAttributes(userProfile!!, attrs);
    return api(user.accessToken).updateCurrentUser(data);
  };

  const connectStrava = async () => {
    const data = await api(user.accessToken).authorizeStrava();
    window.location.href = data.redirect_url;
  };

  const disconnectStrava = async () => {
    setStravaLoading(true);
    const data = await api(user.accessToken).deauthorizeStrava();
    setStravaConnected(false);
    setStravaLoading(false);
    setSearchParams({});
  };

  const claimAthlete = async (e) => {
    e.preventDefault();
    setIsClaiming(true);
    const data = await api(user.accessToken).claimAthlete(selectedAthleteId!!);
    navigate(0);
  };

  const saveAthlete = async (e) => {
    e.preventDefault();
    setIsSavingAthlete(true);
    const { personal_url, location } = userProfile!!.athlete!!;
    const data = await api(user.accessToken).updateAthlete(userProfile!!.athlete!!.uuid, {
      personal_url: personal_url || null,
      location: location || null
    });
    setIsSavingAthlete(false);
    setShowAthleteSaved(true);
    setTimeout(() => setShowAthleteSaved(false), 1500);
  };

  return (
    <>
      <PageHeader title="Settings" subTitle="Manage your profile settings" />
      <MainContent>
        <PaddedContent>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <div className="mb-12">
                <Form
                  onSubmit={() => handleSubmit(['display_name'])}
                  submitButtonText="Save Profile"
                  disabled={!userProfile?.display_name}
                >
                  <div className="space-y-12">
                    <div className="pb-6">
                      <h2 className="text-base font-semibold leading-7">Player Profile</h2>
                      <p className="mt-1 text-sm leading-6">
                        This information will be displayed on public fantasy leaderboards.
                      </p>
                      <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                        <label className="form-control text-sm sm:col-span-4">
                          <div htmlFor="display_name" className="label">
                            Display Name
                          </div>
                          <input
                            type="text"
                            value={userProfile?.display_name || ''}
                            onChange={(e) =>
                              setUserProfile({ ...userProfile!!, display_name: e.target.value })
                            }
                            name="display_name"
                            id="display_name"
                            className="input input-bordered text-sm"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="mb-12">
                <Form
                  onSubmit={() => handleSubmit(['email', 'email_notifications'])}
                  submitButtonText="Save Settings"
                  disabled={!userProfile?.email}
                >
                  <div className="space-y-12">
                    <div className="pb-6">
                      <h2 className="text-base font-semibold leading-7">Notifications</h2>
                      <p className="mt-1 text-sm leading-6">
                        Get notified by email when events open for picks.
                      </p>
                      <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                        <label className="form-control text-sm sm:col-span-4">
                          <div className="label">Email</div>
                          <input
                            type="email"
                            value={userProfile?.email || ''}
                            onChange={(e) =>
                              setUserProfile({ ...userProfile!!, email: e.target.value })
                            }
                            name="email"
                            id="email"
                            className="input input-bordered text-sm"
                          />
                        </label>
                        <label className="form-control text-sm sm:col-span-4">
                          <div className="label">Email Notifications</div>
                          <input
                            type="checkbox"
                            checked={userProfile?.email_notifications}
                            onChange={(e) =>
                              setUserProfile({
                                ...userProfile!!,
                                email_notifications: !userProfile!!.email_notifications
                              })
                            }
                            className="toggle toggle-primary"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="mb-12">
                <div className="space-y-12">
                  <div className="pb-6">
                    <h2 className="text-base font-semibold leading-7">Athlete Profile</h2>
                    {Boolean(userProfile!!.athlete) ? (
                      <div>
                        <p className="mt-1 text-sm leading-6">
                          Manage the information displayed on{' '}
                          <Link
                            className="hyperlink underline"
                            to={`/athletes/${userProfile!!.athlete.profile_slug}`}
                          >
                            your public athlete profile
                          </Link>
                          .
                        </p>
                        <div className="mt-5 grid grid-cols-1 gap-y-4 sm:grid-cols-6">
                          <label className="form-control text-sm sm:col-span-4">
                            <div className="label mb-2 p-0">Strava</div>
                            <div>
                              <p className="mb-3 text-sm leading-6">
                                {stravaConnected ? (
                                  <span>
                                    Your Strava account is connected. You can disconnect and
                                    reconnect at any time.
                                  </span>
                                ) : (
                                  <span>Connect your Strava account to sync your stats.</span>
                                )}
                              </p>
                              {stravaLoading ? (
                                <Loading />
                              ) : stravaConnected ? (
                                <button onClick={disconnectStrava} className="btn btn-link">
                                  <StravaIcon />
                                  Disconnect Strava
                                </button>
                              ) : (
                                <ConnectWithStravaButton onClick={connectStrava} />
                              )}
                            </div>
                          </label>
                          <form
                            onSubmit={saveAthlete}
                            className="grid grid-cols-1 gap-x-6 gap-y-4 sm:col-span-4 sm:grid-cols-6"
                          >
                            <label className="form-control text-sm sm:col-span-6">
                              <div className="label">Personal URL</div>
                              <input
                                type="url"
                                onChange={(e) =>
                                  setUserProfile({
                                    ...userProfile!!,
                                    athlete: {
                                      ...userProfile!!.athlete,
                                      personal_url: e.target.value
                                    }
                                  })
                                }
                                value={userProfile!!.athlete.personal_url || ''}
                                name="personal_url"
                                id="personal_url"
                                className="input input-bordered text-sm"
                              />
                            </label>
                            <label className="form-control text-sm sm:col-span-6">
                              <div className="label">Location</div>
                              <input
                                type="text"
                                onChange={(e) =>
                                  setUserProfile({
                                    ...userProfile!!,
                                    athlete: {
                                      ...userProfile!!.athlete,
                                      location: e.target.value
                                    }
                                  })
                                }
                                value={userProfile!!.athlete.location || ''}
                                name="location"
                                id="location"
                                className="input input-bordered text-sm"
                              />
                            </label>
                            <div className="mt-4 flex items-center justify-start gap-x-6 sm:col-span-4">
                              <button
                                type="submit"
                                disabled={isLoading || isSavingAthlete}
                                className="btn btn-primary"
                              >
                                {isSavingAthlete ? 'Saving...' : 'Save Profile'}
                              </button>
                              {showAthleteSaved && (
                                <span className="badge badge-primary ml-2 text-xs">Saved!</span>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 sm:grid-cols-6">
                        <p className="mt-1 text-sm leading-6 sm:col-span-6">
                          Connect your account to your athlete profile.
                        </p>
                        <div className="mt-5 grid grid-cols-1 gap-y-4 sm:col-span-4">
                          {userProfile!!.athlete_claims.some((ac) => ac.status === 'pending') && (
                            <label className="form-control pl-0 text-sm sm:col-span-4">
                              <div className="label pl-0">Pending Review</div>
                              <FlashMessage type="announcement">
                                <span className="text-xs">
                                  Your athlete profile claim is being processed. Thanks!
                                </span>
                              </FlashMessage>
                            </label>
                          )}
                          <label className="form-control text-sm sm:col-span-4">
                            <div className="label">Select Athlete</div>
                            <AthleteSelect
                              className="mt-2"
                              onChange={({ value }) => setSelectedAthleteId(value)}
                            />
                            <p className="mt-4 text-xs leading-6">
                              Connecting your account to an athlete will allow you to manage your
                              public profile, add a personal link, and (optionally) sync your Strava
                              stats for public display on the fantasy platform. We will usually
                              process your claim within 6-12 hours. If we can't verify that your
                              account is associated with the profile you've claimed, we may reach
                              out via Instagram to confirm that the request is authentic.
                            </p>
                          </label>
                          <div className="flex items-center justify-start gap-x-6">
                            <button
                              disabled={isLoading || isClaiming || !selectedAthleteId}
                              className="btn btn-primary"
                              onClick={claimAthlete}
                            >
                              {isClaiming ? '...' : 'Claim Profile'}
                            </button>
                          </div>
                          <div className="sm:col-span-4"></div>
                          <p className="text-xs leading-6">
                            If your profile does not yet exist and you'd like to create one, please{' '}
                            <a
                              href="https://freetrail.com/contact"
                              target="_blank"
                              className="hyperlink"
                            >
                              send us a message
                            </a>{' '}
                            with your info.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </PaddedContent>
      </MainContent>
      <Footer />
    </>
  );
};

export default Settings;
