import React, { useContext } from 'react';
import Footer from '../components/footer';
import { loginAndRedirect } from '../lib/auth';
import { useAuth0 } from '@auth0/auth0-react';
import ThemeContext from '../context/theme-context';

const Home = ({}: {}) => {
  const theme = useContext(ThemeContext);
  const { loginWithRedirect } = useAuth0();

  const getFeatureImage = (image: string) => {
    const baseUrl = 'https://d2gwq01zobnbgs.cloudfront.net/assets/content/features';
    return theme.isDarkMode ? `${baseUrl}/dark/${image}.png` : `${baseUrl}/light/${image}.png`;
  };

  return (
    <>
      <div
        className="hero min-h-[600px]"
        style={{
          backgroundImage:
            'url(https://d2gwq01zobnbgs.cloudfront.net/assets/content/hero-runner.jpg)'
        }}
      >
        <div className="hero-overlay bg-primary bg-opacity-40 dark:bg-opacity-50"></div>
        <div className="hero-content text-neutral-content text-center">
          <div className="max-w-3xl">
            <img
              src="/images/ft-logo.svg"
              alt="Freetrail Fantasy"
              className="invert mx-auto mb-6"
            />
            <h1 className="mb-5 text-3xl font-extrabold tracking-tight text-white/90 lg:text-4xl">
              Fantasy Trail Running
            </h1>
            <p className="mb-5 text-lg text-white/90">
              Pick your favorites for the world's most exciting trail races.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-base-200 py-12">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight">How it works</h2>
          </div>

          <div className="mt-10">
            <div className="grid grid-cols-1 gap-x-12 gap-y-16 pb-4 sm:grid-cols-2 lg:grid-cols-3">
              <div className="flex flex-col items-center">
                <div className="h-48 w-full overflow-hidden rounded-lg shadow-lg">
                  <img
                    src={getFeatureImage('races')}
                    alt="Race calendar"
                    className="h-full w-full object-cover"
                  />
                </div>
                <h3 className="mt-4 text-lg font-medium">Browse races</h3>
                <p className="mt-2 text-center">
                  In 2024, we featured over 50 trail races from 13 different countries.
                </p>
              </div>

              <div className="flex flex-col items-center">
                <div className="h-48 w-full overflow-hidden rounded-lg shadow-lg">
                  <img
                    src={getFeatureImage('athlete')}
                    alt="Athlete profile"
                    className="h-full w-full object-cover object-top"
                  />
                </div>
                <h3 className="mt-4 text-lg font-medium">Research athletes</h3>
                <p className="mt-2 text-center">
                  Get to know the trail runners you're considering for each race using athlete
                  profiles.
                </p>
              </div>

              <div className="flex flex-col items-center">
                <div className="h-48 w-full overflow-hidden rounded-lg shadow-lg">
                  <img
                    src={getFeatureImage('picks')}
                    alt="Picks"
                    className="h-full w-full object-cover"
                  />
                </div>
                <h3 className="mt-4 text-lg font-medium">Make your picks</h3>
                <p className="mt-2 text-center">
                  You can update your picks as many times as you want before the race starts.
                </p>
              </div>

              <div className="flex flex-col items-center">
                <div className="h-48 w-full overflow-hidden rounded-lg shadow-lg">
                  <img
                    src={getFeatureImage('scorecard')}
                    alt="Scorecard"
                    className="h-full w-full object-cover"
                  />
                </div>
                <h3 className="mt-4 text-lg font-medium">Check your scorecard</h3>
                <p className="mt-2 text-center">
                  View your scorecard after each race to see how your picks fared.
                </p>
              </div>

              <div className="flex flex-col items-center">
                <div className="h-48 w-full overflow-hidden rounded-lg shadow-lg">
                  <img
                    src={getFeatureImage('leaderboard')}
                    alt="Leaderboards"
                    className="h-full w-full object-cover"
                  />
                </div>
                <h3 className="mt-4 text-lg font-medium">Climb the leaderboard</h3>
                <p className="mt-2 text-center">
                  Measure your prognostication prowess against other players and see how you stack
                  up.
                </p>
              </div>

              <div className="flex flex-col items-center">
                <div className="h-48 w-full overflow-hidden rounded-lg shadow-lg">
                  <img
                    src={getFeatureImage('league')}
                    alt="Leagues"
                    className="h-full w-full object-cover"
                  />
                </div>
                <h3 className="mt-4 text-lg font-medium">Join a league</h3>
                <p className="mt-2 text-center">
                  Create your own league, select races, and invite your friends to compete against
                  one another.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="from-accent to-primary bg-gradient-to-r">
        <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:flex lg:items-center lg:justify-between lg:px-8 lg:py-16">
          <h2 className="text-primary-content text-3xl font-extrabold tracking-tight sm:text-4xl">
            <span className="block text-center sm:text-left">Ready to play?</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="mx-auto inline-flex rounded-md shadow sm:mx-0">
              <a
                href="javascript:void(0)"
                onClick={() => loginAndRedirect(loginWithRedirect)}
                className="btn btn-secondary"
              >
                Register or Sign In
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
